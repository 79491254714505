import Link from 'next/link';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { InternalLinkFieldsFragment, LinkFieldsFragment } from '@news/gql';
import { type TGroupId, appendUtmTags } from '@news/tracking';

import {
  EntertainmentIcon,
  ExternalLinkIcon,
  GlobeIcon,
  InfoIcon,
  PlayIcon,
  SportIcon,
  TableauIcon,
  Tv4Icon,
  TvIcon,
} from 'components/icons';
import { getHost } from 'lib/helpers';
import type { StaticEntry } from 'shared/types';

import { MenuItemLink } from './style';

interface LinkProps {
  title: string;
  icon?: IconTag | null;
  onClick?: () => void;
}

interface ArticleLinkProps extends LinkProps {
  slug: string;
  id: string;
}

const ArticleLink = ({ id, slug, title, icon, onClick }: ArticleLinkProps) => {
  const as = `/artikel/${id}/${slug}`;
  const queryParams = [`id=${id}`, `slug=${slug}`, `type=Article`, `expandedIds=MainFeed-${id}`];
  const href = `/?${queryParams.join('&')}`;
  const key = `link-Article-${id}`;

  return (
    <Link legacyBehavior key={key} as={as} href={href} passHref>
      <a role="link" tabIndex={0} onClick={onClick}>
        {icon && <IconChooser tag={icon} />}
        {title}
      </a>
    </Link>
  );
};

interface InternalLinkProps extends LinkProps {
  slug: string;
}

const InternalLink = ({ slug, title, icon, onClick }: InternalLinkProps) => {
  const defaultSlug = slug ?? '/';
  const href = defaultSlug.startsWith('/') ? defaultSlug : `/${defaultSlug}`;
  const key = `link-InternalLink-${href}`;

  return (
    <Link legacyBehavior key={key} href={href} passHref>
      <a role="link" tabIndex={0} onClick={onClick}>
        {icon && <IconChooser tag={icon} />}
        {title}
      </a>
    </Link>
  );
};

interface NewsFeedLinkProps extends LinkProps {
  slug: string;
}

const NewsFeedLink = ({ slug, title, icon, onClick }: NewsFeedLinkProps) => {
  const defaultSlug = slug ?? '/';
  const as = defaultSlug.startsWith('/') ? defaultSlug : `/${defaultSlug}`;
  const key = `link-Newsfeed-${as}`;

  return (
    <Link legacyBehavior key={key} href={as} passHref>
      <a role="link" tabIndex={0} onClick={onClick}>
        {icon && <IconChooser tag={icon} />}
        {title}
      </a>
    </Link>
  );
};

interface ExternalLinkProps extends LinkProps {
  link: string;
}

const ExternalLink = ({ link, title, icon, onClick }: ExternalLinkProps) => {
  const key = `link-ExternalLink-${link}`;

  return (
    <a
      aria-describedby="external-link-description"
      key={key}
      href={appendUtmTags(link, 'Link', false)}
      target="_blank"
      className="external-link"
      onClick={onClick}
    >
      {icon && <IconChooser tag={icon} />}
      {title}
      <IconChooser tag="external-link" />
    </a>
  );
};

interface GenerateLink {
  link: LinkFieldsFragment | StaticEntry;
  trackingName: TGroupId;
  trackingIndex: number;
}

export const GenericLink = ({ link, trackingName, trackingIndex }: GenerateLink) => {
  if (link.__typename === 'ReactNode') return link.element;

  const { id, href, title, targetType } = link;
  const icon = link.icon as IconTag | null;
  if (!href || !title) return null;

  const getLinkComponent = () => {
    switch (targetType) {
      case 'InternalLink':
        return InternalLink({ slug: href, title, icon });
      case 'Article':
        return ArticleLink({ id, slug: href, title, icon });
      case 'Newsfeed':
        return NewsFeedLink({ slug: href, title, icon });
      case 'ExternalLink':
        return ExternalLink({ link: href, title, icon });
      default:
        return null;
    }
  };
  return (
    <ItemTracker
      disableImpressionTracking
      item={link}
      positionData={{
        groupId: trackingName,
        index: trackingIndex,
      }}
    >
      {getLinkComponent()}
    </ItemTracker>
  );
};

type IconTag = 'news' | 'sport' | 'entertainment' | 'schedule' | 'tv' | 'play' | 'tv4' | 'info' | 'external-link';

interface ChooserProp {
  tag: IconTag;
}

const IconChooser = ({ tag }: ChooserProp) => {
  const icon = () => {
    switch (tag) {
      case 'news':
        return <GlobeIcon />;
      case 'sport':
        return <SportIcon />;
      case 'entertainment':
        return <EntertainmentIcon />;
      case 'schedule':
        return <TableauIcon />;
      case 'tv':
        return <TvIcon />;
      case 'play':
        return <PlayIcon />;
      case 'tv4':
        return <Tv4Icon />;
      case 'info':
        return <InfoIcon />;
      case 'external-link':
        return <ExternalLinkIcon />;
      default:
        return null;
    }
  };
  return <IconHolder>{icon()}</IconHolder>;
};

const IconHolder = styled.span.attrs({
  'aria-hidden': 'true',
})`
  width: ${sizeUnits[40]};
  height: ${sizeUnits[24]};
`;

const getLinkArguments = ({ path }: { path: string }): { as: string; href: string; key: string } | null => {
  if (path.startsWith('/artikel/')) {
    return extractLinkArgumentsFromPath({
      path,
      type: 'Article',
      short: 'artikel',
    });
  } else if (path.startsWith('/klipp/va/')) {
    return extractLinkArgumentsFromPath({
      path,
      type: 'VideoAsset',
      short: 'va',
    });
  }
  return null;
};

const extractLinkArgumentsFromPath = ({
  path,
  type,
  short,
}: {
  path: string;
  type: string;
  short: string;
}): { as: string; href: string; key: string } | null => {
  const parts = path.split('/');
  const { id, slug } = type === 'Article' ? { id: parts[2], slug: parts[3] } : { id: parts[3], slug: parts[4] };
  if (!id) return null;

  const as = `${type === 'Article' ? '' : '/klipp'}/${short}/${id}/${slug}`;
  const queryParams = [`id=${id}`, `slug=${slug}`, `type=${type}`, `expandedIds=MainFeed-${id}`];
  const href = `/?${queryParams.join('&')}`;
  const key = `link-${type}-${id}`;

  return { as, href, key };
};

export const generateInternalLinkFromContextMenuItem = ({
  contextMenuItem,
  active,
}: {
  contextMenuItem: InternalLinkFieldsFragment;
  active: boolean;
}): ReactNode => {
  const { url, title } = contextMenuItem;
  if (!url) return null;

  const path = url.replace(getHost(), '');
  const linkArguments = getLinkArguments({ path });

  // If link arguments cannot be extracted, use the path for both as and href.
  // It will most likely trigger a full page reload,
  // but we shouldn't risk and assume that a link is in a certain format.
  const { key, as, href } = linkArguments
    ? linkArguments // articles, video assets, video files
    : {
        key: `link-${contextMenuItem.id}`,
        as: path,
        href: path,
      }; // /tabla, /program etc.

  return (
    <Link legacyBehavior key={key} as={as} href={href} passHref>
      <MenuItemLink $active={active} aria-current={active && 'page'} href={path}>
        {title}
      </MenuItemLink>
    </Link>
  );
};
