import { debugTracking } from '@news/tracking';

interface GTMEvent {
  event: string;
  [field: string]: unknown;
}

declare global {
  interface Window {
    dataLayer: unknown[];
    _cInfo: unknown[];
  }
}

export const DEBUG_TRACKING = process.env.NODE_ENV !== 'production' && process.env.DEBUG_TRACKING === 'true';

const getDataLayer = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }
  return window.dataLayer;
};

export const dataLayerPush = (data: GTMEvent) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (!data) {
    return;
  }
  getDataLayer()?.push(data);
  if (DEBUG_TRACKING) {
    debugTracking(data.event, data, 'GTM');
  }
};
