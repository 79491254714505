import type { NewsFeedItem } from '@news/lib';

import { type VideoAssetWithParentFeaturedId } from 'contexts/PlayNextContentContext';

import { flattenFeed } from './flattenFeed';

export const getVideosForPNC = (feed: NewsFeedItem[]): VideoAssetWithParentFeaturedId[] => {
  return flattenFeed(feed).reduce((arr: VideoAssetWithParentFeaturedId[], feedItem) => {
    if (feedItem.__typename === 'VideoAsset') {
      const isAlreadyAdded = arr.some(({ id }) => id === feedItem.id);
      if (isAlreadyAdded) return arr;

      return [...arr, feedItem];
    } else if (feedItem.__typename === 'Article' || feedItem.__typename === 'RelatedArticle') {
      if (feedItem.featuredAsset?.__typename === 'VideoPlayerData' && feedItem.featuredAsset.asset) {
        const featuredId = feedItem.featuredAsset.asset.id;
        const isAlreadyAdded = arr.some(({ id }) => id === featuredId);
        if (isAlreadyAdded) return arr;
        return [...arr, { ...feedItem.featuredAsset.asset, parentFeaturedId: feedItem.id }];
      }
    }

    return arr;
  }, []);
};
