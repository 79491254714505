import React from 'react';

import { cssSize } from './iconUtils';

interface PlayIconProps extends Pick<React.SVGProps<SVGSVGElement>, 'fill' | 'stroke'> {
  size?: number;
}

export const PlayIcon = ({ size = 24, stroke = 'currentColor', fill = 'none' }: PlayIconProps) => (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 24 24"
    style={cssSize(size)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="play-icon"
  >
    <path
      d="M6 21L20 12L6 3L6 21Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill}
    />
  </svg>
);
