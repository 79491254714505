import styled from 'styled-components';

import { type Variant, desktop, mobile } from '@news/design-tokens/src/typography';

import { theme } from 'styles/theme';

const shouldForwardProp = (prop: string) => prop !== 'variant';

export const Typography = styled.p.withConfig({ shouldForwardProp })<{ variant: Variant }>(
  ({ variant }) => `
    margin: unset;
    font-size: ${mobile[variant].fontSize};
    font-weight: ${mobile[variant].fontWeight};
    line-height: ${mobile[variant].lineHeight};
    letter-spacing: ${mobile[variant].letterSpacing ?? ''};
    text-decoration-line: ${mobile[variant].textDecorationLine ?? ''};
    text-transform: ${mobile[variant].textTransform ?? ''};
    color: ${mobile[variant].color ?? ''};
    
    ${theme.mq.tablet} {
        font-size: ${desktop[variant].fontSize};
        font-weight: ${desktop[variant].fontWeight};
        line-height: ${desktop[variant].lineHeight};
        letter-spacing: ${desktop[variant].letterSpacing ?? ''};
        text-decoration-line: ${desktop[variant].textDecorationLine ?? ''};
        text-transform: ${desktop[variant].textTransform ?? ''};
        color: ${mobile[variant].color ?? ''};
    }
    `
);
