import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const InstagramIcon = ({ size = 20, color = '#000000' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={size} height={size} fill="#D8D8D8" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8603 4.82232C17.1321 4.82232 16.5421 5.41238 16.5421 6.13966C16.5421 6.86694 17.1321 7.457 17.8603 7.457C18.5876 7.457 19.1758 6.86694 19.1758 6.13966C19.1758 5.41238 18.5876 4.82232 17.8603 4.82232ZM12 15.6584C9.97916 15.6584 8.34072 14.0208 8.34072 11.9991C8.34072 9.97825 9.97916 8.3398 12 8.3398C14.0208 8.3398 15.6593 9.97825 15.6593 11.9991C15.6593 14.0208 14.0208 15.6584 12 15.6584ZM12 6.36196C8.88687 6.36196 6.36288 8.88595 6.36288 11.9991C6.36288 15.1122 8.88687 17.6371 12 17.6371C15.1131 17.6371 17.6371 15.1131 17.6371 11.9991C17.6371 8.88595 15.1131 6.36196 12 6.36196ZM12 3C14.9311 3 15.2787 3.01098 16.436 3.06404C19.411 3.19943 20.8015 4.611 20.936 7.56404C20.9899 8.72128 21 9.06892 21 12C21 14.932 20.989 15.2787 20.936 16.436C20.8006 19.3853 19.4146 20.8006 16.436 20.936C15.2787 20.9881 14.9329 21 12 21C9.06892 21 8.72128 20.9881 7.56404 20.936C4.58172 20.7997 3.19943 19.3817 3.06404 16.435C3.01098 15.2778 3 14.9311 3 11.9991C3 9.068 3.01189 8.72128 3.06404 7.56312C3.20035 4.611 4.5863 3.19852 7.56404 3.06312C8.7222 3.01098 9.06892 3 12 3Z"
      fill={color}
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8603 4.82232C17.1321 4.82232 16.5421 5.41238 16.5421 6.13966C16.5421 6.86694 17.1321 7.457 17.8603 7.457C18.5876 7.457 19.1758 6.86694 19.1758 6.13966C19.1758 5.41238 18.5876 4.82232 17.8603 4.82232ZM12 15.6584C9.97916 15.6584 8.34072 14.0208 8.34072 11.9991C8.34072 9.97825 9.97916 8.3398 12 8.3398C14.0208 8.3398 15.6593 9.97825 15.6593 11.9991C15.6593 14.0208 14.0208 15.6584 12 15.6584ZM12 6.36196C8.88687 6.36196 6.36288 8.88595 6.36288 11.9991C6.36288 15.1122 8.88687 17.6371 12 17.6371C15.1131 17.6371 17.6371 15.1131 17.6371 11.9991C17.6371 8.88595 15.1131 6.36196 12 6.36196ZM12 3C14.9311 3 15.2787 3.01098 16.436 3.06404C19.411 3.19943 20.8015 4.611 20.936 7.56404C20.9899 8.72128 21 9.06892 21 12C21 14.932 20.989 15.2787 20.936 16.436C20.8006 19.3853 19.4146 20.8006 16.436 20.936C15.2787 20.9881 14.9329 21 12 21C9.06892 21 8.72128 20.9881 7.56404 20.936C4.58172 20.7997 3.19943 19.3817 3.06404 16.435C3.01098 15.2778 3 14.9311 3 11.9991C3 9.068 3.01189 8.72128 3.06404 7.56312C3.20035 4.611 4.5863 3.19852 7.56404 3.06312C8.7222 3.01098 9.06892 3 12 3Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);
