import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { type LinkFieldsFragment, type LinkHierarchyFieldsFragment, useMenuQuery } from '@news/gql';
import { PRIMARY_TAB_MENU_DESKTOP_ID } from '@news/lib';
import { GroupId } from '@news/tracking';

import { LinkComponent, getLinkHref } from 'components/Link';
import { Typography } from 'components/Typography';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { isClient } from 'lib/helpers';
import { isPathPartOfLinkHierarchy } from 'lib/helpers/isPathPartOfLinkHierarchy';
import { hideScrollbar } from 'styles/mixins/hideScrollbar';
import { theme } from 'styles/theme';

import { PRIMARY_TAB_MENU_DESKTOP_LINKS_FALLBACK } from './constants';

export const PrimaryTabMenuDesktop = () => {
  const currentRoute = useCurrentRoute();

  const { data, loading, error } = useMenuQuery({
    variables: {
      id: PRIMARY_TAB_MENU_DESKTOP_ID,
    },
  });

  const isErrorOnServer = error && !isClient;

  // If an error occurs on the server, it should return the loading-state
  // as the query is going to be rerun och the client. Otherwise there will be a hydration error.
  if (loading || isErrorOnServer) return null;

  const primaryTabMenuLinks = error ? PRIMARY_TAB_MENU_DESKTOP_LINKS_FALLBACK : data?.menu?.groups[0]?.links;

  if (!primaryTabMenuLinks) return null;

  return (
    <Wrapper aria-label="Primär meny">
      <LinkList>
        {primaryTabMenuLinks.map((linkGroupItem, index) => (
          <LinkGroupItem
            key={linkGroupItem.id}
            linkGroupItem={linkGroupItem}
            currentPath={currentRoute.feed}
            index={index}
          />
        ))}
      </LinkList>
    </Wrapper>
  );
};

const LinkList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  ${theme.mq.tablet} {
    ${hideScrollbar()}
    position: relative;
    overflow-x: scroll;
  }
`;

const Wrapper = styled.nav`
  display: none;
  ${theme.mq.tablet} {
    padding: 0 ${sizeUnits[8]};
    display: flex;
    overflow-x: hidden;
  }
`;

interface LinkGroupItemProps {
  linkGroupItem: LinkFieldsFragment | LinkHierarchyFieldsFragment;
  currentPath: string;
  index: number;
}

const LinkGroupItem = ({ linkGroupItem, currentPath, index }: LinkGroupItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const isCurrent: boolean = useMemo(() => {
    if (linkGroupItem.__typename === 'Link') {
      return getLinkHref(linkGroupItem) === currentPath;
    }
    return isPathPartOfLinkHierarchy({ path: currentPath, linkHierarchy: linkGroupItem });
  }, [currentPath, linkGroupItem]);

  let link: LinkFieldsFragment | null;
  let title: string | null;

  switch (linkGroupItem.__typename) {
    case 'Link':
      link = linkGroupItem;
      title = linkGroupItem.title;
      break;
    case 'LinkHierarchy':
      link = linkGroupItem.parentLink;
      title = linkGroupItem.categoryTitle;
      break;
  }

  useEffect(() => {
    if (isCurrent) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [isCurrent]);

  if (!link) return null; //This might happen if the editor adds a LinkHierarchy without a parentLink to the primary-tab-menu-desktop entity

  return (
    <li ref={ref}>
      <PrimaryTabMenuLink current={isCurrent} link={link} groupId={GroupId.PrimaryTabMenuDesktop} index={index}>
        <Typography variant="title3-strong">{title}</Typography>
      </PrimaryTabMenuLink>
    </li>
  );
};

const PrimaryTabMenuLink = styled(LinkComponent)`
  display: block;
  color: ${colors.black[70]};
  padding: ${sizeUnits[4]} ${sizeUnits[8]};
  margin: 0 ${sizeUnits[12]};

  &[aria-current='page'] {
    color: ${colors.red.tv4};
  }
`;
