import type { PageType } from '../page';
import { sendKilkayaTrackRequest } from './sendKilkayaRequest';
import type { KilkayaItemLocationType } from './types';

type KilkayaChannel = 'mobile' | 'tablet' | 'desktop' | 'mobile_ios_app' | 'mobile_android_app';

const DEFAULT_DOMAIN = 'https://www.tv4.se';

interface CommonKilkayaPayload {
  channel: KilkayaChannel;
  appId: string;
  pagePath: string;
  pageType: PageType;
  pageTitle: string;
  pageSection?: string;
  domain?: string;
}

export interface KilkayaPageView extends CommonKilkayaPayload {
  wordcount?: number;
  authors?: string;
  tags?: string[];
  publishDate?: string;
  modifiedDate?: string;
  imageUrl?: string;
  referrer?: string;
  newSession?: boolean;
}

export const trackKilkayaPageView = (data: KilkayaPageView, debug?: boolean) => {
  const ptp = data.pagePath === '/' ? 'frontpage' : data.pageType;
  sendKilkayaTrackRequest(
    {
      l: 'p',
      _h: 'pageView',
      _s: data.appId,
      c: data.channel,
      u: (data.domain ?? DEFAULT_DOMAIN) + data.pagePath,
      ptl: data.pageTitle,
      psn: data.pageSection,
      ptp: ptp,
      pwct: data.wordcount,
      par: data.authors,
      ptg: data.tags,
      ppt: data.publishDate,
      pmt: data.modifiedDate,
      pig: data.imageUrl,
      r: data.referrer || '_', // Default to _ for empty and undefined values
      ns: data.newSession,
    },
    debug
  );
};

export interface KilkayaPositionData {
  row?: number;
  positionName?: Exclude<KilkayaItemLocationType, 'frontpage'>;
}

export interface KilkayaClick extends CommonKilkayaPayload, KilkayaPositionData {
  targetPath: string;
  targetTitle: string;
  targetType?: PageType;
  targetSection?: string;
}

export const trackKilkayaClick = (data: KilkayaClick, debug?: boolean) => {
  const ptp = data.pagePath === '/' ? 'frontpage' : data.pageType;
  const tptp = data.targetPath === '/' ? 'frontpage' : data.targetType;
  const positionName = data.pagePath === '/' && data.positionName === 'feed' ? 'frontpage' : data.positionName;

  sendKilkayaTrackRequest(
    {
      l: 'c',
      _h: 'click',
      _s: data.appId,
      c: data.channel,
      u: (data.domain ?? DEFAULT_DOMAIN) + data.pagePath,
      ptl: data.pageTitle,
      ptp: ptp,
      psn: data.pageSection,
      tu: (data.domain ?? DEFAULT_DOMAIN) + data.targetPath,
      tptl: data.targetTitle,
      tptp: tptp,
      tpsn: data.targetSection,
      cr: data.row,
      cp: data.row !== undefined ? data.row + 1 : undefined,
      po: positionName && data.row !== undefined ? `${positionName}_row${data.row}_pos${data.row + 1}` : undefined,
    },
    debug
  );
};

/**
 * @internal
 */
export interface KilkayaVideo extends CommonKilkayaPayload {
  videoId: string;
  videoDuration: number;
  videoTitle?: string;
  thumbnail?: string;
}

const VIDEO_URL_PREFIX = 'https://www.tv4play.se/iframe/video/';

export const trackKilkayaVideo = (data: KilkayaVideo, debug?: boolean) => {
  const ptp = data.pagePath === '/' ? 'frontpage' : data.pageType;

  sendKilkayaTrackRequest(
    {
      l: 'v',
      vst: 'manual',
      _h: 'video',
      _s: data.appId,
      c: data.channel,
      u: (data.domain ?? DEFAULT_DOMAIN) + data.pagePath,
      ptl: data.pageTitle,
      ptp,
      psn: data.pageSection,
      vvt: 0,
      vid: VIDEO_URL_PREFIX + data.videoId,
      drn: data.videoDuration,
      vtl: data.videoTitle,
      vig: data.thumbnail,
    },
    debug
  );
};
