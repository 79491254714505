import { memo } from 'react';

import { isDev, isStage } from 'lib/helpers';

import { Typography } from './Typography';

const BRANCH_NAME = process.env.BRANCH;
const excludedBranches = ['master', 'main', 'develop'];

export const BranchInfo = memo(function BranchInfoComponent() {
  if ((isStage() || isDev) && BRANCH_NAME && !excludedBranches.includes(BRANCH_NAME)) {
    return (
      <div style={{ backgroundColor: 'coral', textAlign: 'center', padding: '5px', color: 'white' }}>
        <Typography variant="body2">{BRANCH_NAME}</Typography>
      </div>
    );
  }

  return null;
});
