import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

type VideoPlayerContextData = {
  activeVideoPlayerId: string | null;
  setActiveVideoPlayerId: (divElement: string | null) => void;
};

const VideoPlayerContext = createContext<VideoPlayerContextData>({
  activeVideoPlayerId: null,
  setActiveVideoPlayerId: () => {},
});

VideoPlayerContext.displayName = 'VideoPlayerContext';

export const VideoPlayerContextProvider = ({ children }: React.PropsWithChildren) => {
  const [activeVideoPlayerId, setActiveVideoPlayerId] = useState<string | null>(null);
  const router = useRouter();

  useEffect(() => {
    const callback = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setActiveVideoPlayerId(null);
      }
    };

    router.events.on('routeChangeComplete', callback);

    return () => {
      router.events.off('routeChangeComplete', callback);
    };
  }, [router.events]);

  return (
    <VideoPlayerContext.Provider
      value={{
        activeVideoPlayerId: activeVideoPlayerId,
        setActiveVideoPlayerId: setActiveVideoPlayerId,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useActiveVideoPlayerContext = () => {
  return useContext(VideoPlayerContext);
};
