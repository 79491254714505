import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const MailIcon = ({ className, size = 24 }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V6.01384C22.0007 5.96739 21.9981 5.92072 21.9922 5.87416C21.9721 5.71422 21.9142 5.56604 21.8278 5.43882C21.7717 5.35624 21.7036 5.28249 21.626 5.22009C21.4566 5.08398 21.2419 5.0019 21.0082 5.00003C21.0055 5.00001 21.0028 5 21 5M18.2758 7H5.72427L12 12.3249L18.2758 7ZM4 8.1599L11.3531 14.3989C11.7263 14.7155 12.2738 14.7155 12.647 14.3989L20 8.15999V17H4V8.1599Z"
    />
  </svg>
);
