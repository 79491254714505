import { BurgerMenuButton, BurgerMenuLinkText } from './styles';

export const CookieButton = () => {
  function onClick() {
    if (typeof window === 'undefined') return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.Optanon?.ToggleInfoDisplay();
  }

  return (
    <BurgerMenuButton onClick={onClick}>
      <BurgerMenuLinkText variant="body1">Cookie-inställningar</BurgerMenuLinkText>
    </BurgerMenuButton>
  );
};
