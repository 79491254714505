import { isClient } from './isClient';
import { setStorageKey } from './setStorageKey';

export const setLocalStorageKey = (key: string, value: string): void => {
  if (!key || key === '' || !value || value === '' || !isClient) {
    console.error('Storage need a key and a value');
    return;
  }

  setStorageKey(window.localStorage, key, value);
};
