import { getItemUrlForFeedItem } from '@news/lib';

import type { KilkayaClick } from '../kilkaya';
import type { TrackedItem } from './getItemData';

export const getItemKilkayaData = (
  item: TrackedItem
): Pick<KilkayaClick, 'targetPath' | 'targetTitle' | 'targetType' | 'targetSection'> | undefined => {
  // For Kilkaya we only are interested in clickable items
  if (!('__typename' in item)) {
    return undefined;
  }

  switch (item.__typename) {
    case 'Article':
    case 'RelatedArticle':
    case 'VideoAsset':
      return {
        targetPath: getItemUrlForFeedItem(item) ?? '',
        targetTitle: item.title ?? '',
        targetType: item.__typename === 'VideoAsset' ? 'video' : 'article',
        targetSection: item.__typename === 'VideoAsset' ? undefined : (item.feedOrigins?.[0]?.slug ?? undefined),
      };
    case 'BreakingNews':
      return {
        targetPath: getItemUrlForFeedItem(item) ?? '',
        targetTitle: item.title ?? '',
        targetType: 'breaking-news',
        targetSection: '/just-nu',
      };
    default:
      // For all other types, do not send any Kilkaya data
      return undefined;
  }
};
