export const getRelativeTimeFromNow = (date: number) => {
  const msFromNow = Date.now() - date;

  return getStringRepresentation(msFromNow);
};

function getStringRepresentation(msFromNow: number) {
  switch (true) {
    case msFromNow < toMilliseconds({ s: 45 }):
      return 'några sekunder sedan';

    case msFromNow < toMilliseconds({ m: 1, s: 30 }):
      return '1 minut sedan';

    case msFromNow < toMilliseconds({ m: 59, s: 30 }):
      return `${Math.round(millisecondsToMinutes(msFromNow))} minuter sedan`;

    case msFromNow < toMilliseconds({ h: 1, m: 30 }):
      return '1 timme sedan';

    case msFromNow < toMilliseconds({ h: 22 }):
      return `${Math.round(millisecondsToHours(msFromNow))} timmar sedan`;

    case msFromNow < toMilliseconds({ d: 1, h: 12 }):
      return '1 dag sedan';

    case msFromNow < toMilliseconds({ d: 26 }):
      return `${Math.round(millisecondsToDays(msFromNow))} dagar sedan`;

    case msFromNow < toMilliseconds({ d: 45 }):
      return '1 månad sedan';

    case msFromNow < toMilliseconds({ d: 320 }):
      return `${Math.round(millisecondsToMonths(msFromNow))} månader sedan`;

    case msFromNow < toMilliseconds({ d: 547 }): //1.5 years
      return '1 år sedan';

    default:
      return `${Math.round(millisecondsToYears(msFromNow))} år sedan`;
  }
}

function toMilliseconds({ s = 0, m = 0, h = 0, d = 0 }: { s?: number; m?: number; h?: number; d?: number }) {
  return secondsToMilliseconds(s) + minutesToMilliseconds(m) + hoursToMilliseconds(h) + daysToMilliseconds(d);
}

function secondsToMilliseconds(seconds: number) {
  return seconds * 1000;
}

function minutesToMilliseconds(minutes: number) {
  const seconds = minutes * 60;
  return secondsToMilliseconds(seconds);
}

function hoursToMilliseconds(hours: number) {
  const minutes = hours * 60;
  return minutesToMilliseconds(minutes);
}

function daysToMilliseconds(days: number) {
  const hours = days * 24;
  return hoursToMilliseconds(hours);
}

function millisecondsToSeconds(ms: number) {
  return ms / 1000;
}

function millisecondsToMinutes(ms: number) {
  const seconds = millisecondsToSeconds(ms);
  return seconds / 60;
}

function millisecondsToHours(ms: number) {
  const minutes = millisecondsToMinutes(ms);
  return minutes / 60;
}

function millisecondsToDays(ms: number) {
  const hours = millisecondsToHours(ms);
  return hours / 24;
}

function millisecondsToMonths(ms: number) {
  const days = millisecondsToDays(ms);
  return days / 30;
}

function millisecondsToYears(ms: number) {
  const months = millisecondsToMonths(ms);
  return months / 12;
}
