import type { NewsFeedItem } from '../types';

export const getVideoAssetIds = (items: NewsFeedItem[]): string[] => {
  return items.reduce((ids: string[], item) => {
    return item.__typename === 'Article' &&
      item.featuredAsset?.__typename === 'VideoPlayerData' &&
      item.featuredAsset.asset
      ? [...ids, item.featuredAsset.asset.id]
      : ids;
  }, []);
};
