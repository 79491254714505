import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

interface IError {
  message: string;
}

const generateDummyApiKey = () => {
  return [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};

const getApiKey = () => {
  const envApiKey = process.env.BUGSNAG_API_KEY;
  if (!envApiKey) return '';

  if (envApiKey === 'dev') {
    return generateDummyApiKey();
  } else {
    return envApiKey ?? '';
  }
};

const bugsnagClient = Bugsnag.start({
  apiKey: getApiKey(),
  appVersion: process.env.JOB_COMMIT,
  collectUserIp: false,
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ['prod', 'stage'],
  autoDetectErrors: false,
  plugins: [new BugsnagPluginReact(React)],
});

export { bugsnagClient };

export const notifyBugsnag = ({ error, query, slug }: { error: IError; query?: string; slug?: string }): void => {
  const e: Error =
    query || slug
      ? new Error(`Fetch failed, ${error.message}, query: '${query}', slug: '${slug}'`)
      : new Error(`Error: ${error.message}`);
  bugsnagClient.notify(e);
};
