import { type CSSProperties } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { type Variant } from '@news/design-tokens/src/typography';

export const LINK_GROUP_ITEM_CONFIGURATIONS: {
  [key in 'large' | 'small']: {
    variant: Variant;
    color: CSSProperties['color'];
    verticalPadding: CSSProperties['paddingTop'];
    iconSize: number;
  };
} = {
  large: { variant: 'title2', color: colors.black[100], verticalPadding: sizeUnits[8], iconSize: 24 },
  small: { variant: 'body1', color: colors.black[70], verticalPadding: 0, iconSize: 20 },
};
