import type { IncomingMessage, ServerResponse } from 'node:http';
import { createContext } from 'react';

export type TServerContext = { request: IncomingMessage; response?: ServerResponse<IncomingMessage> } | null;

const ServerContext = createContext<TServerContext>(null);

if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
  ServerContext.displayName = 'ServerContext';
}

export { ServerContext };
