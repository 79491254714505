import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { usePageContext } from 'contexts/PageContext';

export const useCurrentRoute = () => {
  const { pageData } = usePageContext();
  const router = useRouter();

  const currentRoute = useMemo(() => {
    const pathname = router.asPath.split('?')[0] ?? ''; //router.pathname should not be used due to rewrites
    const feed = pageData?.page_feed || pathname; // "|| pathname" enables server side rendering
    return {
      path: pathname,
      feed,
    };
  }, [pageData, router]);

  return currentRoute;
};
