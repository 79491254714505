export const BurgerIcon = ({ className, color = 'black' }: { className?: string; color?: string }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Burger icon</title>
    <path d="M3 6.5H21M3 12.5H21M3 18.5H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
