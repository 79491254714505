import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { LinkHierarchyFieldsFragment } from '@news/gql';
import { GroupId } from '@news/tracking';

import { getLinkHref } from 'components/Link';
import { Chevron, ExternalLinkIcon } from 'components/icons';
import { isPathPartOfLinkHierarchy } from 'lib/helpers/isPathPartOfLinkHierarchy';

import type { MenuIcon } from './IconChooser';
import { IconChooser } from './IconChooser';
import { LINK_GROUP_ITEM_CONFIGURATIONS } from './constants';
import { BurgerMenuLink, BurgerMenuLinkText } from './styles';

interface LinkHierarchyProps {
  linkHierarchy: LinkHierarchyFieldsFragment;
  sectionIndex: number;
  groupIndex: number;
  currentPath: string;
}

export const LinkHierarchy = ({ linkHierarchy, sectionIndex, groupIndex, currentPath }: LinkHierarchyProps) => {
  const hasSelectedEntry = useMemo(
    () => isPathPartOfLinkHierarchy({ path: currentPath, linkHierarchy }),
    [currentPath, linkHierarchy]
  );

  const [isOpen, setIsOpen] = useState(hasSelectedEntry);

  useEffect(() => {
    setIsOpen(hasSelectedEntry);
  }, [hasSelectedEntry]);

  const childLinks = linkHierarchy.childLinks;
  const parentLink = linkHierarchy.parentLink;

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  if (childLinks.length === 0 && !parentLink) return null;

  const linkGroupItemConfig =
    sectionIndex === 0 ? LINK_GROUP_ITEM_CONFIGURATIONS.large : LINK_GROUP_ITEM_CONFIGURATIONS.small;

  function Category() {
    if (parentLink) {
      return (
        <>
          <LinkHierarchyCategoryLink
            current={getLinkHref(parentLink) === currentPath}
            index={groupIndex}
            groupId={GroupId.DrawerMenu(sectionIndex)}
            link={parentLink}
          >
            <CategoryTitle variant={linkGroupItemConfig.variant} $color={linkGroupItemConfig.color}>
              <CategoryIcon
                $active={hasSelectedEntry}
                icon={linkHierarchy.icon as MenuIcon | null}
                size={linkGroupItemConfig.iconSize}
              />
              {linkHierarchy.categoryTitle}
            </CategoryTitle>
          </LinkHierarchyCategoryLink>

          <ExpandCategoryButton
            onClick={toggleOpen}
            aria-label={`Expandera ${linkHierarchy.categoryTitle}`}
            aria-expanded={isOpen}
            aria-controls={`dropdown-${linkHierarchy.id}`}
          >
            <Chevron direction={isOpen ? 'up' : 'down'} />
          </ExpandCategoryButton>
        </>
      );
    }
    return (
      <CategoryButtonNoLink onClick={toggleOpen} aria-expanded={isOpen} aria-controls={`dropdown-${linkHierarchy.id}`}>
        <CategoryTitle variant={linkGroupItemConfig.variant} $color={linkGroupItemConfig.color}>
          <CategoryIcon
            $active={hasSelectedEntry}
            icon={linkHierarchy.icon as MenuIcon | null}
            size={linkGroupItemConfig.iconSize}
          />
          {linkHierarchy.categoryTitle}
        </CategoryTitle>
        <Chevron direction={isOpen ? 'up' : 'down'} />
      </CategoryButtonNoLink>
    );
  }

  return (
    <>
      <CategoryWrapper>
        <Category />
      </CategoryWrapper>

      {childLinks.length > 0 && (
        <SubcategoriesWrapper $isOpen={isOpen} id={`dropdown-${linkHierarchy.id}`}>
          {childLinks.map((link, index) => (
            <li key={link.id}>
              <BurgerMenuLink
                current={getLinkHref(link) === currentPath}
                index={index}
                groupId={GroupId.DrawerMenuLinkHierarchy(sectionIndex, groupIndex)}
                link={link}
              >
                <BurgerMenuLinkText variant="body1">
                  {link.title}
                  {link.targetType === 'ExternalLink' && <ExternalLinkIcon size={16} />}
                </BurgerMenuLinkText>
              </BurgerMenuLink>
            </li>
          ))}
        </SubcategoriesWrapper>
      )}
    </>
  );
};

const LinkHierarchyCategoryLink = styled(BurgerMenuLink)`
  padding-right: ${sizeUnits[16]};
`;

const SubcategoriesWrapper = styled.ul<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  list-style: none;
  padding-left: ${sizeUnits[36]};
`;

const CategoryTitle = styled(BurgerMenuLinkText)<{ $color?: string }>`
  color: ${({ $color }) => $color};
`;

const CategoryWrapper = styled.div`
  display: flex;
  color: ${colors.black[100]};
  align-items: center;
`;

const ExpandCategoryButton = styled.button`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: ${sizeUnits[8]} 0;
`;

const CategoryButtonNoLink = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: ${sizeUnits[8]} 0;
`;

const CategoryIcon = styled(IconChooser)<{ $active: boolean }>`
  color: ${({ $active }) => ($active ? colors.red.tv4 : colors.black[100])};
  ${({ $active }) => !$active && 'opacity: 0.7;'}
`;
