import React from 'react';

import { cssSize } from './iconUtils';

export const LeftSliderArrowIcon = ({ color = '#FF3334' }: { color?: string }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(15, 16)}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8L0.292894 8.70711C-0.0976304 8.31658 -0.0976305 7.68342 0.292894 7.29289L1 8ZM15 9L1 9L1 7L15 7L15 9ZM1.70711 7.29289L8.70711 14.2929L7.29289 15.7071L0.292894 8.70711L1.70711 7.29289ZM0.292894 7.29289L7.29289 0.292894L8.70711 1.70711L1.70711 8.70711L0.292894 7.29289Z"
      fill={color}
    />
  </svg>
);
