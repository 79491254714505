import { css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

export const menuFade = css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to right, transparent 0%, ${colors.white[100]} 100%);
    width: ${sizeUnits[40]};
  }
`;
