import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { type LinkFieldsFragment } from '@news/gql';
import { GroupId } from '@news/tracking';

import { LinkComponent, getLinkHref } from 'components/Link';
import { Typography } from 'components/Typography';
import { ExternalLinkIcon } from 'components/icons/ExternalLinkIcon';
import { useSecondaryTabMenu } from 'contexts/SecondaryTabMenuContext';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { menuFade } from 'styles/mixins/fade';
import { hideScrollbar } from 'styles/mixins/hideScrollbar';
import { MAX_CONTENT_WIDTH, theme } from 'styles/theme';

export const SecondaryTabMenu = () => {
  const linkHierarchy = useSecondaryTabMenu();

  const currentRoute = useCurrentRoute();

  if (!linkHierarchy) return null;

  return (
    <SecondaryTabMenuWrapper aria-label="Sekundär meny">
      <SecondaryTabMenuOptionsWrapper>
        {linkHierarchy.childLinks.map((link, index) => (
          <SecondaryTabMenuListItem key={link.id} link={link} index={index} currentPath={currentRoute.feed} />
        ))}
      </SecondaryTabMenuOptionsWrapper>
    </SecondaryTabMenuWrapper>
  );
};

const SecondaryTabMenuListItem = ({
  link,
  index,
  currentPath,
}: {
  link: LinkFieldsFragment;
  index: number;
  currentPath: string;
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const linkHref = getLinkHref(link);
  const isLinkCurrent = linkHref === currentPath || (linkHref === '/just-nu' && currentPath.startsWith('/just-nu')); //The latter check is needed to ensure that "Senaste nytt" is selected when toggling breaking news)

  useEffect(() => {
    if (isLinkCurrent) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [isLinkCurrent]);

  return (
    <li id={link.id} ref={ref}>
      <SecondaryTabMenuLink current={isLinkCurrent} groupId={GroupId.SecondaryTabMenu} index={index} link={link}>
        <SecondaryTabMenuLinkText variant={isLinkCurrent ? 'tab-active-text' : 'tab-inactive-text'}>
          {link.title}
          {link.targetType === 'ExternalLink' && <ExternalLinkIcon size={14} />}
        </SecondaryTabMenuLinkText>
      </SecondaryTabMenuLink>
    </li>
  );
};

const SecondaryTabMenuLink = styled(LinkComponent)`
  display: block;
  color: ${colors.black[70]};
  white-space: nowrap;

  &[aria-current='page'] {
    color: ${colors.red.tv4};
  }

  padding: ${sizeUnits[12]} ${sizeUnits[16]};

  ${theme.mq.tablet} {
    padding: ${sizeUnits[12]} 0 ${sizeUnits[8]};
  }
`;

const SecondaryTabMenuLinkText = styled(Typography)`
  display: flex;
  gap: ${sizeUnits[4]};
  align-items: center;
`;

export const SecondaryTabMenuWrapper = styled.nav`
  box-shadow: 0 ${sizeUnits[4]} ${sizeUnits[4]} 0 ${colors.black[5]};
  ${menuFade}
`;

const SecondaryTabMenuOptionsWrapper = styled.ul`
  position: relative;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  display: flex;
  overflow-x: scroll;
  padding-right: ${sizeUnits[12]};

  ${hideScrollbar()}

  ${theme.mq.tablet} {
    padding: ${sizeUnits[4]} ${sizeUnits[40]} 0;
    gap: ${sizeUnits[32]};
  }
`;
