import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import type { GroupFieldsFragment, LinkFieldsFragment, LinkHierarchyFieldsFragment } from '@news/gql';
import { GroupId } from '@news/tracking';

import { getLinkHref } from 'components/Link';
import { ExternalLinkIcon } from 'components/icons';
import { usePageContext } from 'contexts/PageContext';

import { CookieButton } from './CookieButton';
import { IconChooser, type MenuIcon } from './IconChooser';
import { LinkHierarchy } from './LinkHierarchy';
import { LINK_GROUP_ITEM_CONFIGURATIONS } from './constants';
import { BurgerMenuLink, BurgerMenuLinkText, LinkGroupItemWrapper } from './styles';

interface Props {
  group: GroupFieldsFragment;
  sectionIndex: number;
}

export const BurgerMenuSection = ({ group, sectionIndex }: Props) => {
  const { pageData } = usePageContext();
  const currentPath = pageData?.page_path || '';

  return (
    <BurgerMenuLinkGroup>
      {group.links.map((link, index) => (
        <LinkGroupItem
          key={link.id}
          link={link}
          currentPath={currentPath}
          sectionIndex={sectionIndex}
          groupIndex={index}
        />
      ))}
    </BurgerMenuLinkGroup>
  );
};

interface LinkGroupItemProps {
  link: LinkFieldsFragment | LinkHierarchyFieldsFragment;
  currentPath: string;
  sectionIndex: number;
  groupIndex: number;
}

const LinkGroupItem = ({ link, currentPath, sectionIndex, groupIndex }: LinkGroupItemProps) => {
  const linkGroupItemConfig =
    sectionIndex === 0 ? LINK_GROUP_ITEM_CONFIGURATIONS.large : LINK_GROUP_ITEM_CONFIGURATIONS.small;

  if (link.__typename === 'Link') {
    const current = getLinkHref(link) === currentPath;

    return (
      <>
        <LinkGroupItemWrapper $verticalPadding={linkGroupItemConfig.verticalPadding}>
          <BurgerMenuLink current={current} index={groupIndex} groupId={GroupId.DrawerMenu(sectionIndex)} link={link}>
            <BurgerMenuLinkText variant={linkGroupItemConfig.variant} $color={linkGroupItemConfig.color}>
              <LinkIcon size={linkGroupItemConfig.iconSize} $active={current} icon={link.icon as MenuIcon | null} />
              {link.title}
              {link.targetType === 'ExternalLink' && <ExternalLinkIcon size={linkGroupItemConfig.iconSize - 4} />}
            </BurgerMenuLinkText>
          </BurgerMenuLink>
        </LinkGroupItemWrapper>
        {sectionIndex === 2 && groupIndex === 0 && (
          <li>
            <CookieButton />
          </li>
        )}
      </>
    );
  }
  return (
    <LinkGroupItemWrapper $verticalPadding={linkGroupItemConfig.verticalPadding}>
      <LinkHierarchy
        currentPath={currentPath}
        sectionIndex={sectionIndex}
        groupIndex={groupIndex}
        linkHierarchy={link}
      />
    </LinkGroupItemWrapper>
  );
};

const BurgerMenuLinkGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LinkIcon = styled(IconChooser)<{ $active: boolean }>`
  color: ${({ $active }) => ($active ? colors.red.tv4 : colors.black[100])};
  ${({ $active }) => !$active && 'opacity: 0.7;'}
`;
