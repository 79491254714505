import { DEBUG_TRACKING, dataLayerPush } from 'tracking';

import { trackKilkayaPageView } from '@news/tracking';
import type { KilkayaPageView, PageData, PageEvent } from '@news/tracking';

import { isClient, isMobile } from 'lib/helpers';

import { updateSession } from './kilkayaSession';

declare global {
  interface Window {
    k5aMeta: Record<string, unknown>;
  }
}

export const pushPageEvent = (data: PageData) => {
  if (!isClient) return;

  const event: PageEvent = {
    event: 'page',
    ...data,
  };
  setTimeout(() => {
    dataLayerPush(event);
  });
};

export type KilkayaPageEventPayload = Omit<KilkayaPageView, 'channel' | 'appId'>;

export const pushKilkayaPageEvent = (event: KilkayaPageEventPayload) => {
  if (!isClient) return;

  // Set metadata so that it can be consume in the web player as well.
  // Important that disablePageview is set to true, so that the automated event is not sent to Kilkaya
  window.k5aMeta = { type: event.pageType, disablePageview: true };

  const newSession = updateSession();

  if (process.env.NODE_ENV === 'production' || DEBUG_TRACKING) {
    const fullEvent: KilkayaPageView = {
      appId: 'js',
      channel: isMobile() ? 'mobile' : 'desktop',
      newSession,
      domain: window.location.origin,
      ...event,
    };
    setTimeout(() => {
      trackKilkayaPageView(fullEvent, DEBUG_TRACKING);
    }, 0);
  }
};

const IS_INITIAL_PAGE_KEY = 'k5a.initialPage';

export const getKilkayaReferrer = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmReferrer = searchParams.get('utm_source') ?? searchParams.get('utm_referrer');

  // Note: Session storage gets cleared on every new session, e.g. when the user opens the app in a new tab.
  const isInitialPage = window.sessionStorage.getItem(IS_INITIAL_PAGE_KEY);
  if (isInitialPage === null) {
    window.sessionStorage.setItem(IS_INITIAL_PAGE_KEY, 'false');
  }
  // The default referrer is either "_" for the initial page, and the hostname for internal navigation.
  const defaultReferrer = isInitialPage === null ? '_' : window.location.host;

  // Referrer priority: UTM Tag, document.referrer, or the default
  return utmReferrer || document.referrer || defaultReferrer;
};
