import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const SportIcon = ({ size = 24, className }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7.37794L5.75604 4.49249L6.7202 2.74023L11.9762 5.6323L16.7203 2.76086L17.7559 4.47186L13 7.35043V9.78029L16.8099 12.3191L19.18 11.6744L20.5319 8.93566L22.3253 9.82088L21.1398 12.2228L22.5561 13.2953L21.3487 14.8898L19.6655 13.6151L17.3865 14.2349L15.9038 18.1165L17.4528 20.3284L15.8146 21.4757L14.2597 19.2555H9.74033L8.18544 21.4757L6.54722 20.3284L8.09626 18.1165L6.61351 14.2349L4.33459 13.6151L2.65135 14.8898L1.44394 13.2953L2.77172 12.2898L1.21313 9.92926L2.88216 8.82728L4.74932 11.6552L7.19011 12.3191L11 9.78029V7.37794ZM12 11.5173L8.5855 13.7926L9.90829 17.2555H14.0918L15.4146 13.7926L12 11.5173Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 20.9993C16.9706 20.9993 21.0001 16.9698 21.0001 11.9993C21.0001 7.0287 16.9706 2.99927 12.0001 2.99927C7.0295 2.99927 3.00006 7.0287 3.00006 11.9993C3.00006 16.9698 7.0295 20.9993 12.0001 20.9993ZM12.0001 22.9993C18.0752 22.9993 23.0001 18.0744 23.0001 11.9993C23.0001 5.92414 18.0752 0.999268 12.0001 0.999268C5.92493 0.999268 1.00006 5.92414 1.00006 11.9993C1.00006 18.0744 5.92493 22.9993 12.0001 22.9993Z"
      fill="currentColor"
    />
  </svg>
);
