import { DEBUG_TRACKING, dataLayerPush } from 'tracking';

import { type ItemEvent, type KilkayaClick, trackKilkayaClick } from '@news/tracking';

import { isClient, isMobile } from 'lib/helpers';

export const pushItemEvent = (event: ItemEvent) => {
  if (!isClient) return;
  setTimeout(() => {
    dataLayerPush(event);
  }, 0);
};

export const pushKilkayaClickEvent = (event: Omit<KilkayaClick, 'channel' | 'appId'>) => {
  if (!isClient) return;

  const fullEvent: KilkayaClick = {
    appId: 'js',
    channel: isMobile() ? 'mobile' : 'desktop',
    domain: window.location.origin,
    ...event,
  };

  setTimeout(() => {
    trackKilkayaClick(fullEvent, !!DEBUG_TRACKING);
  }, 0);
};
