import {
  EntertainmentIcon,
  GlobeIcon,
  LifeStyleIcon,
  MailIcon,
  NyhetsmorgonLogoIcon,
  SportIcon,
  TableauIcon,
} from 'components/icons';

export type MenuIcon = 'news' | 'sport' | 'entertainment' | 'schedule' | 'lifestyle' | 'nyhetsmorgon' | 'mail';

interface IconChooserProps {
  icon: MenuIcon | null;
  className?: string;
  size?: number;
}

export const IconChooser = ({ icon, className, size = 24 }: IconChooserProps) => {
  switch (icon) {
    case 'news':
      return <GlobeIcon className={className} size={size} />;
    case 'sport':
      return <SportIcon className={className} size={size} />;
    case 'entertainment':
      return <EntertainmentIcon className={className} size={size} />;
    case 'schedule':
      return <TableauIcon className={className} size={size} />;
    case 'lifestyle':
      return <LifeStyleIcon className={className} size={size} />;
    case 'nyhetsmorgon':
      return <NyhetsmorgonLogoIcon className={className} size={size} />;
    case 'mail':
      return <MailIcon className={className} size={size} />;
    default:
      return null;
  }
};
