interface Props {
  size?: number;
  className?: string;
}

export const LifeStyleIcon = ({ size = 24, className }: Props) => (
  <svg
    role="img"
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.629028L22 10.0081V21C22 21.5523 21.5523 22 21 22H14.5714C14.0191 22 13.5714 21.5523 13.5714 21V16.6422C13.5714 15.7744 12.8679 15.0708 12 15.0708C11.1321 15.0708 10.4286 15.7744 10.4286 16.6422V21C10.4286 21.5523 9.98086 22 9.42857 22H3C2.44772 22 2 21.5523 2 21V10.0081L12 0.629028ZM4 10.8744V20H8.42857V16.6422C8.42857 14.6698 10.0276 13.0708 12 13.0708C13.9724 13.0708 15.5714 14.6698 15.5714 16.6422V20H20V10.8744L12 3.37106L4 10.8744Z"
    />
  </svg>
);
