import '@tv4/avod-web-player/dist/avod-web-player.all.css';
import { ApolloClientProvider } from 'gql/ApolloClientProvider';
import 'intersection-observer';
import type { NextComponentType } from 'next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { BranchInfo } from 'components/BranchInfo';
import { Footer } from 'components/footer';
import { Menu } from 'components/menu';
import { UserMessages } from 'components/messages';
import { BurgerMenuContextProvider } from 'contexts/BurgerMenuContext';
import { ConsentContextProvider } from 'contexts/ConsentContext';
import { PageContextProvider } from 'contexts/PageContext';
import { PlayNextContentContextProvider } from 'contexts/PlayNextContentContext';
import { SecondaryTabMenuProvider } from 'contexts/SecondaryTabMenuContext';
import { VideoPlayerContextProvider } from 'contexts/VideoPlayerContext';
import { bugsnagClient } from 'lib/bugsnagClient';
import { withServerContext } from 'lib/withServerContext';

import { GlobalStyle } from '../styles/globalStyles';
import { theme } from '../styles/theme';

const ErrorBoundary =
  bugsnagClient.getPlugin('react')?.createErrorBoundary(React) ?? (({ children }) => <>{children}</>);

const AdPlacementImpact = dynamic(
  () => import('components/ad-placements/AdPlacement').then((module) => module.AdPlacementImpact),
  {
    ssr: false,
  }
);

interface PageProps {
  featureFlags?: string[];
  isPreview?: boolean;
  consentCookie: string;
}

const AppContainer = ({ Component, pageProps }: AppProps<PageProps>) => {
  return (
    <ErrorBoundary>
      <ApolloClientProvider>
        <StyleSheetManager enableVendorPrefixes>
          <ConsentContextProvider>
            <PageContextProvider>
              <ThemeProvider theme={theme}>
                <SecondaryTabMenuProvider>
                  <BurgerMenuContextProvider>
                    <PlayNextContentContextProvider>
                      <VideoPlayerContextProvider>
                        <Head>
                          <title>TV4.se</title>
                          <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
                        </Head>
                        <BranchInfo />
                        <AdPlacementImpact />
                        <UserMessages />
                        <Menu />
                        <Component {...pageProps} />
                        <Footer />
                        <GlobalStyle />
                      </VideoPlayerContextProvider>
                    </PlayNextContentContextProvider>
                  </BurgerMenuContextProvider>
                </SecondaryTabMenuProvider>
              </ThemeProvider>
            </PageContextProvider>
          </ConsentContextProvider>
        </StyleSheetManager>
      </ApolloClientProvider>
    </ErrorBoundary>
  );
};

export default withServerContext(AppContainer as NextComponentType<AppContext, AppInitialProps>);
