import type { NextRouter } from 'next/router';

export const getFeedSlug = (router: NextRouter) => {
  const { feedSlug } = router.query as {
    feedSlug?: string;
  };

  if (feedSlug) {
    return feedSlug;
  }

  const { feed, subFeed } = router.query as {
    feed?: string;
    subFeed?: string;
  };

  return feed && subFeed ? `/${feed}/${subFeed}` : `/${feed ?? ''}`;
};
