import React, { createContext, useMemo } from 'react';

import { type GroupFieldsFragment, type LinkHierarchyFieldsFragment, useMenuQuery } from '@news/gql';
import { BURGER_MENU_ID } from '@news/lib';

import { BURGER_MENU_LINK_GROUPS_FALLBACK } from 'components/menu/constants';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { useIsMobileWidth } from 'hooks/useIsMobileWidth';
import { isClient } from 'lib/helpers';
import { isFeedItemPage } from 'lib/helpers/isFeedItemPage';
import { isPathPartOfLinkHierarchy } from 'lib/helpers/isPathPartOfLinkHierarchy';

const SecondaryTabMenuContext = createContext<LinkHierarchyFieldsFragment | undefined | null>(undefined);
SecondaryTabMenuContext.displayName = 'SecondaryTabMenuContext';

export const useSecondaryTabMenu = () => React.useContext(SecondaryTabMenuContext);

export const SecondaryTabMenuProvider = ({ children }: React.PropsWithChildren) => {
  const currentRoute = useCurrentRoute();
  const isMobile = useIsMobileWidth();

  const { data, error } = useMenuQuery({
    variables: {
      id: BURGER_MENU_ID,
    },
  });

  const isErrorOnServer = error && !isClient;

  const burgerMenuLinkGroupOne = isErrorOnServer
    ? undefined //if there is an error on the server, we don't want to show the fallback as the query will be rerun on the client which would lead to a hydration error
    : error
      ? BURGER_MENU_LINK_GROUPS_FALLBACK[0]
      : data?.menu?.groups[0];

  const linkHierarchy = useMemo(() => {
    // If we are on a feed item page, we don't want to show the secondary tab menu on mobile to save vertical space
    if (isMobile && isFeedItemPage(currentRoute.path)) {
      return null;
    }
    return getSecondaryTabMenuOptions({
      group: burgerMenuLinkGroupOne,
      path: currentRoute.feed,
    });
  }, [burgerMenuLinkGroupOne, currentRoute.feed, currentRoute.path, isMobile]);

  return <SecondaryTabMenuContext.Provider value={linkHierarchy}>{children}</SecondaryTabMenuContext.Provider>;
};

function getSecondaryTabMenuOptions({ group, path }: { group?: GroupFieldsFragment; path: string }) {
  if (isFeedItemPage(path)) {
    return null;
  }

  if (!group) {
    return null;
  }

  return group.links.find((linkGroupItem) => {
    if (linkGroupItem.__typename === 'Link') {
      return false;
    }
    return isPathPartOfLinkHierarchy({ path, linkHierarchy: linkGroupItem });
  }) as LinkHierarchyFieldsFragment | undefined;
}
