const SESSION_KEY = 'k5a.session';

// The session is marked as expired after 30 minutes
const SESSION_EXPIRES_MS = 1000 * 60 * 30;

interface KilkayaSession {
  ts: number;
}

const setSession = (newSession: KilkayaSession) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(newSession));
};

/**
 * @returns {boolean} `true` if the user's sessions was expired or non-existent
 */
export const updateSession = () => {
  const now = new Date().getTime();
  const newSession: KilkayaSession = { ts: now };

  const sessionString = window.localStorage.getItem(SESSION_KEY);
  if (!sessionString) {
    setSession(newSession);
    return true;
  }
  try {
    const currentSession = JSON.parse(sessionString) as KilkayaSession;
    // Update the session with the new timestamp
    setSession(newSession);
    // Check if the old timestamp was expired or not
    if (newSession.ts - currentSession.ts >= SESSION_EXPIRES_MS) {
      return true;
    }
    return false;
  } catch (_error) {
    // Probably error parsing the JSON, might be edited by the user.
    setSession(newSession);
    return true;
  }
};
