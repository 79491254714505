import type { NewsFeedItem } from '../types';

export function getNumberOfLiveArticlesInTopOfFeed(feedWithoutFocusedFeedItem: NewsFeedItem[]) {
  let numberOfLiveArticlesInTopOfFeed = 0;

  for (const item of feedWithoutFocusedFeedItem) {
    if (item.__typename !== 'Article') {
      break;
    }

    if (item.live) {
      numberOfLiveArticlesInTopOfFeed++;
    } else {
      break;
    }
  }

  return numberOfLiveArticlesInTopOfFeed;
}
