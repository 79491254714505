import { isClient } from './isClient';

export const trackSifoEvent = (val: string): void => {
  if (isClient && window.document.cookie.match('__codnt') === null) {
    if (!window._cInfo) window._cInfo = [];
    try {
      window._cInfo.push({ cmd: '_trackContentPath', val }, { cmd: '_executeTracking' });
    } catch (e) {
      console.log(e);
    }
  } else {
    console.log(`Sifo tracking is disabled, skipping "${val}".`);
  }
};
