import React from 'react';

import { ServiceMessageWrapper } from 'components/infoBar/serviceMessages/ServiceMessageWrapper';
import { UnsupportedBrowser } from 'components/infoBar/unsupportedBrowser';
import { NetworkStatusMessage } from 'components/network-status-message';

import { Section } from './style';

const UserMessages = () => {
  return (
    <Section>
      <UnsupportedBrowser />
      <ServiceMessageWrapper />
      <NetworkStatusMessage />
    </Section>
  );
};

export { UserMessages };
