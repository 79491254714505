import { debugTracking } from '../logging';
import { parseKilkayaValue } from './parseKilkayaValue';
import type { KilkayaClickEvent, KilkayaPageViewEvent, KilkayaVideoEvent } from './types';

/**
 * This is our internal API towards Kilkaya.
 * The Kilkaya documentation can be found here: https://docs.google.com/document/d/1xnsB0Jjl-V8zJtbcxrjvHfj3BOy20roD8X5WUjBHHZo
 */

const KILKAYA_DOMAIN = 'https://cl-eu2.k5a.io';
const KILKAYA_INSTALLATION_ID = '61823e7e1ee74e7c3a3f67a2';

type DefaultKeys = 'i' | 'dt';

/** @internal */
export type KilkayaEvent =
  | Omit<KilkayaPageViewEvent, DefaultKeys>
  | Omit<KilkayaClickEvent, DefaultKeys>
  | Omit<KilkayaVideoEvent, DefaultKeys>;

export const sendKilkayaTrackRequest = async (event: KilkayaEvent, debug?: boolean) => {
  const parameters = new URLSearchParams({
    i: KILKAYA_INSTALLATION_ID,
    dt: new Date().toISOString(),
  });

  Object.entries(event).forEach(([key, value]) => {
    // Skip empty values
    if (value === undefined || value === '') return;
    parameters.append(key, parseKilkayaValue(value));
  });

  if (debug) {
    const entries = '_searchParams' in parameters ? parameters._searchParams : parameters.entries();
    debugTracking(event._h, Object.fromEntries(entries as string[][]), 'Kilkaya');
    return;
  }

  const trackingRequest = `${KILKAYA_DOMAIN}/?${parameters}`;

  try {
    await fetch(trackingRequest);
  } catch (e) {
    console.warn('Failed to execute Kilkaya request', e);
  }
};
