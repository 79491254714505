import type { ArticleFieldsFragment } from '@news/gql';

export const getImageFromFeatured = (featured: ArticleFieldsFragment['featuredAsset']): string | undefined => {
  switch (featured?.__typename) {
    case 'Image':
      return featured?.url ?? undefined;
    case 'VideoPlayerData':
      return featured.thumbnail ?? featured.asset?.image ?? undefined;
    default:
      return undefined;
  }
};
