import type { NodeDataFieldsFragment } from '@news/gql';

import { HeadingType, NodeType } from './types';
import type { DocumentNode, Node } from './types';

/**
 * This is the distance in characters between each of the ads within a rich text
 * @internal
 */
export const AD_DISTANCE = 600;

/**
 * Inject adds between top level nodes.
 *
 * We count the number of characters of each node, and when we reach the sufficient amount of characters, we insert an ad.
 */
export function injectRichTextAds(baseNode: DocumentNode, data?: NodeDataFieldsFragment[]) {
  if (baseNode.nodeType !== NodeType.Document || !baseNode.children) {
    return baseNode;
  }

  let addCount = 0;
  let charCount = 0;

  const childrenWithAds = baseNode.children.reduce((acc, node) => {
    charCount += getNodeCharCount(node, data);

    if (charCount > AD_DISTANCE) {
      charCount = 0;
      const ad: Node = { nodeType: NodeType.Ad, id: `ad-${++addCount}` };
      if (node.nodeType in HeadingType) {
        // place ad before heading node
        return acc.concat(ad, node);
      } else {
        return acc.concat(node, ad);
      }
    }
    return acc.concat(node);
  }, [] as Node[]);

  return { ...baseNode, children: childrenWithAds };
}

function getNodeCharCount(node: Node, data?: NodeDataFieldsFragment[]): number {
  if (node.content) {
    return node.content.length;
  }

  if (node.children) {
    return node.children.reduce((sum, node): number => {
      return sum + getNodeCharCount(node, data);
    }, 0);
  }

  if (node.id && data) {
    const nodeData = data.find(({ id }) => id === node.id);
    // count characters in FactBox
    if (nodeData?.data && nodeData.data.__typename === 'FactBoxData') {
      return getNodeCharCount(nodeData.data.body);
    }
  }

  return 0;
}
