import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const GlobeIcon = ({ size = 24, className }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 1C5.92765 1 0.999939 5.92772 0.999939 12C0.999939 18.0723 5.92765 23 11.9999 23C18.0722 23 22.9999 18.0723 22.9999 12C22.9999 5.92772 18.0722 1 11.9999 1ZM15.6798 3.78524C15.4336 4.90608 14.4295 5.75151 13.2376 5.75151H10.7376V8.25151C10.7376 8.93901 10.1751 9.50151 9.48757 9.50151H6.98757V12.0015H14.4876C15.1751 12.0015 15.7376 12.564 15.7376 13.2515V17.0015H16.9876C17.734 17.0015 18.3923 17.3262 18.8459 17.8404C20.1888 16.268 20.9999 14.2281 20.9999 12C20.9999 8.343 18.8148 5.19293 15.6798 3.78524ZM3.10566 10.6174C3.03605 11.0681 2.99994 11.5299 2.99994 12C2.99994 16.5393 6.36667 20.2975 10.7374 20.912V19.4991C9.36244 19.4991 8.23744 18.3741 8.23744 16.9991V15.7491L3.10566 10.6174Z"
      fill="currentColor"
    />
  </svg>
);
