import type { FieldFunctionOptions } from '@apollo/client';
import { HttpLink, from } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import uniqBy from 'lodash.uniqby';

import type { LatestBreakingNewsFieldsFragment, PaginatedNewsFeedFieldsFragment } from '@news/gql';
import { combineArrays } from '@news/lib';

export const isBrowser = typeof window !== 'undefined';

export const mergeFeed = (
  existing: PaginatedNewsFeedFieldsFragment['feed'] = null,
  incoming: NonNullable<PaginatedNewsFeedFieldsFragment['feed']>
) => {
  if (existing === null) {
    return incoming;
  }
  if ((existing?.pagingData?.currentPage as number) > (incoming?.pagingData?.currentPage as number)) {
    return existing;
  }
  return {
    ...existing,
    pagingData: {
      ...incoming.pagingData,
    },
    items: uniqBy(combineArrays(existing.items, incoming.items), '__ref'),
  };
};

export const mergeLatestBreakingNews = (
  existing: LatestBreakingNewsFieldsFragment | undefined,
  incoming: LatestBreakingNewsFieldsFragment,
  options: FieldFunctionOptions<unknown, unknown>
): LatestBreakingNewsFieldsFragment => {
  /**
   * By default we append the incoming items after the existing items. To support pagination.
   * But since we also support polling, we need to sort the items by publishedAt, since the
   * polled items will be at the end of the list.
   */
  const items = uniqBy(combineArrays(existing?.items ?? [], incoming.items), '__ref').sort((a, b) => {
    const aPublishedAt = new Date(options.readField('firstPublishedAt', a) ?? '').getTime();
    const bPublishedAt = new Date(options.readField('firstPublishedAt', b) ?? '').getTime();
    return bPublishedAt - aPublishedAt;
  });

  return {
    total: incoming.total,
    items,
    __typename: 'LatestBreakingNews',
  };
};

const commonHttpOptions = {
  uri: process.env.GRAPHQL_URL,
  headers: {
    'client-name': 'tv4se',
    'client-version': isBrowser ? 'browser' : 'server',
    platform: 'web',
    ...(process.env.PREVIEW_API_KEY ? { 'preview-api-key': process.env.PREVIEW_API_KEY } : {}),
  },
};

const persistedQueryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

const httpLink = new HttpLink(commonHttpOptions);

export const persistedQueryhttpLink = () => from([persistedQueryLink, httpLink]);
