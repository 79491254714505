const isBrowser = () => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

export const debugTracking = (eventName: string, data: unknown, service: 'GTM' | 'Kilkaya') => {
  // groupCollapsed is not supported by Expo's logger, so only use it for webs
  const group = isBrowser() ? console.groupCollapsed : console.group;

  group(
    `%cTracking ${service} event %c${eventName}`,
    'color: darkgreen; font-size: 11px',
    'color: lightgrey; font-size: 11px'
  );
  console.log(JSON.stringify(data, null, 2));
  console.groupEnd();
};
