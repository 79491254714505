import { useEffect } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { CloseIcon } from 'components/icons';
import { useBurgerMenu } from 'contexts/BurgerMenuContext';
import { useBlockScrolling } from 'hooks/useBlockScrolling';
import { theme } from 'styles/theme';

import { BurgerMenuLinkGroups } from './BurgerMenuLinkGroups';

export const BurgerMenu = () => {
  const { isOpen, toggle, close } = useBurgerMenu();

  useBlockScrolling(isOpen);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, close]);

  return (
    <>
      <SiteDarkener $isOpen={isOpen} onClick={toggle} aria-hidden />
      <BurgerMenuContainer $isOpen={isOpen} aria-label="Hamburgarmeny" aria-expanded={isOpen}>
        <CloseMenuButtonContainer>
          <CloseMenuButton onClick={toggle} $isOpen={isOpen} aria-label="Stäng hamburgarmeny">
            <CloseIcon />
          </CloseMenuButton>
        </CloseMenuButtonContainer>

        <BurgerMenuLinkGroups />
      </BurgerMenuContainer>
    </>
  );
};

const SiteDarkener = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black[40]};
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: ${theme.zIndex.menu};
`;

const BurgerMenuContainer = styled.nav<{ $isOpen: boolean }>(
  ({ $isOpen }) => `
  z-index: ${theme.zIndex.menu};
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: scroll;
  background: ${colors.white[100]};
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(${$isOpen ? 0 : 100}%);
  ${theme.mq.tablet} {
    width: 340px;
  }
`
);

const CloseMenuButton = styled.button<{ $isOpen: boolean }>`
  padding: ${sizeUnits[8]};
  color: ${colors.black[100]};
  align-items: center;
  justify-content: center;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
`;

const CloseMenuButtonContainer = styled.div`
  z-index: 1;
  top: 0;
  margin-right: ${sizeUnits[8]};
  background-color: ${colors.white[100]};
  justify-content: flex-end;
  position: sticky;
  display: flex;
  padding-top: ${sizeUnits[8]};
  ${theme.mq.tablet} {
    padding-top: ${sizeUnits[16]};
  }
`;
