import type { PaginatedNewsFeedFieldsFragment } from '@news/gql';

import type { NewsEntry } from '../types';

const limitStringLength = (value: string, limit: number): string => {
  const len = value.length;
  return len === 0 || len <= limit ? value : value.substring(0, limit - 3) + '...';
};

/**
 * @internal
 */
export const defaultTitle = 'Nyheter från TV4';

export const getOgTitle = (item: NewsEntry | PaginatedNewsFeedFieldsFragment): string | undefined => {
  const limit = 95; // Maximum recommended OG Title length: 95 characters or under.

  switch (item?.__typename) {
    case 'Article':
    case 'PaginatedNewsFeed':
      return limitStringLength(item.ogTitle || item.metaTitle || item.title || defaultTitle, limit);
    case 'VideoAsset':
      return limitStringLength(item.title || defaultTitle, limit);
  }
};

export const getOgDescription = (item: NewsEntry | PaginatedNewsFeedFieldsFragment): string | undefined => {
  const limit = 200; // Maximum recommended OG Description length: 200 characters or under.
  const fallback = item.title ? `${defaultTitle}: ${item.title}` : defaultTitle;

  switch (item.__typename) {
    case 'Article':
      return limitStringLength(item.ogDescription || item.metaDescription || item.lead || fallback, limit);
    case 'PaginatedNewsFeed':
      return limitStringLength(item.ogDescription || item.metaDescription || fallback, limit);
    case 'VideoAsset':
      return limitStringLength(item.description || fallback, limit);
  }
};

export const getMetaTitle = (item: NewsEntry | PaginatedNewsFeedFieldsFragment): string | undefined => {
  const limit = 60; // Maximum <title> tag length: 60 characters

  switch (item.__typename) {
    case 'Article':
      return limitStringLength(item.metaTitle || item.title || defaultTitle, limit);
    case 'PaginatedNewsFeed':
      return item.slug === '/' ? defaultTitle : limitStringLength(item.metaTitle || item.title || defaultTitle, limit);
    case 'VideoAsset':
      return limitStringLength(item.title || defaultTitle, limit);
  }
};

export const getMetaDescription = (item: NewsEntry | PaginatedNewsFeedFieldsFragment): string | undefined => {
  const limit = 155; // Maximum <meta name="description" tag length: 155 characters
  const fallback = item.title ? `${defaultTitle}: ${item.title}` : defaultTitle;

  switch (item.__typename) {
    case 'Article':
      return limitStringLength(item.metaDescription || item.lead || fallback, limit);
    case 'PaginatedNewsFeed':
      return limitStringLength(item.metaDescription || fallback, limit);
    case 'VideoAsset':
      return limitStringLength(item.description || fallback, limit);
  }
};
