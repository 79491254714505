import React from 'react';

export function useBlockScrolling(shouldBlock: boolean): void {
  React.useEffect(() => {
    if (shouldBlock) {
      document.body.classList.add('block-scrolling');
      return () => document.body.classList.remove('block-scrolling');
    }
  }, [shouldBlock]);
}
