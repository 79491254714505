import type { NewsFeedItem } from './types';

export const BURGER_MENU_ID = 'main-menu';
export const PRIMARY_TAB_MENU_MOBILE_ID = 'primary-tab-menu-mobile';
export const PRIMARY_TAB_MENU_DESKTOP_ID = 'primary-tab-menu-desktop';

export const DEFAULT_LOCALE = 'sv-SE';
export const DEFAULT_TIMEZONE = 'Europe/Stockholm';

/**
 * Do not render ads in the vicinity of these feed items
 */
export const IGNORED_AD_FEED_ITEMS: NewsFeedItem['__typename'][] = [];

export const CHANNELS = {
  TV4: '89415a06d55534db08c9',
  Sjuan: '3bac25bcd34c7e149f56',
  TV12: '1823232ee5c6c868154d',
  'TV4 Fakta': 'e8bd422c976731796f7f',
  'TV4 Guld': '4fe9a692a332bc4ff342',
  'TV4 Film': '8e2ff8120bde29e1c281',
};

export const CHANNEL_LOGOS = {
  [CHANNELS.TV4]: 'https://assets.tv4.se/logos/tv4-logo.png',
  [CHANNELS.Sjuan]: 'https://assets.tv4.se/logos/tv7-logo.png',
  [CHANNELS.TV12]: 'https://assets.tv4.se/logos/tv12-logo.png',
  [CHANNELS['TV4 Fakta']]: 'https://assets.tv4.se/logos/tv4-fakta-logo-2024.png',
  [CHANNELS['TV4 Guld']]: 'https://assets.tv4.se/logos/tv4-guld-logo-2024.png',
  [CHANNELS['TV4 Film']]: 'https://assets.tv4.se/logos/tv4-film-logo-2024.png',
};

export const FALLBACK_ARTICLE_IMAGE = 'https://assets.tv4.se/logos/tv4_16_9.jpg';

export const TV4PLAY_PROGRAM_URL = 'https://www.tv4play.se/program/';

export const FALLBACK_OG_IMAGE = 'https://assets.tv4.se/images/default-og-image.jpg';

export const TV4_FIFTH_ELEMENT_URL = 'https://assets.tv4.se/backgrounds/tv4-fifth-element.png';

export const AD_IN_ARTICLE_KEYWORD = 'in-article';
export const AD_IN_FEED_KEYWORD = 'in-feed';
