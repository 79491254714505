import React from 'react';

interface PlayIconRoundedProps extends Pick<React.SVGProps<SVGSVGElement>, 'stroke'> {
  size?: number;
}

export const PlayIconRounded = ({ size = 24, stroke = 'white' }: PlayIconRoundedProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M8.1094 3.2044C6.78029 2.31833 5 3.27111 5 4.8685V19.1315C5 20.7289 6.78029 21.6816 8.1094 20.7956L18.8066 13.6641C19.9941 12.8724 19.9941 11.1275 18.8066 10.3359L8.1094 3.2044Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
