import { css } from 'styled-components';

const hideScrollbar = () => css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
`;
export { hideScrollbar };
