import { colors } from '@news/lib';

export const TABLET = 767;
export const DESKTOP = 1023;

export const MAX_CONTENT_WIDTH = 1272;
export const BODY_WIDTH_MOBILE = '100%';

export const TOPBAR_HEIGHT_DESKTOP = 65;
export const TOPBAR_HEIGHT_MOBILE = 44;
export const PRIMARY_TAB_MENU_HEIGHT_MOBILE = 72;
export const SECONDARY_TAB_MENU_HEIGHT_DESKTOP = 40;
export const SECONDARY_TAB_MENU_HEIGHT_MOBILE = 38;

//.............0..1..2..3..4..5...6...7...8...9...10..11
const space = [0, 1, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48].map((size) => size + 'px');
//.................0...1...2...3...4...5...6...7...8...9...10..11..12
const fontSizes = [12, 14, 16, 18, 20, 24, 32, 36, 42, 48, 54, 68, 84].map((size) => size + 'px');

const theme = {
  maxContentWidth: MAX_CONTENT_WIDTH,
  zIndex: {
    playerOverlay: 1,
    stickyPlayer: 5,
    menu: 10,
    stickyPlayerFullscreen: 20,
  },
  space: {
    ...space,
    xsmall: space[2], //.... 2 px
    small: space[4], //..... 8 px
    medium: space[6], //... 16 px
    large: space[7], //.... 20 px
    xlarge: space[9], //... 32 px
  },
  fontSizes: {
    ...fontSizes,
    xsmall: fontSizes[0], //.... 12 px
    small: fontSizes[1], //..... 14 px
    medium: fontSizes[2], //... 16 px
    body: fontSizes[2],
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  //      0, 1, 2, 3, 4, 5, 6
  radii: [0, 2, 3, 4, 6, 8, 10].map((size) => size + 'px'),
  borderWidths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((size) => size + 'px'),
  colors,
  mq: {
    mobile: `@media screen and (max-width: ${TABLET - 1}px)`,
    tablet: `@media screen and (min-width: ${TABLET}px)`,
    desktop: `@media screen and (min-width: ${DESKTOP}px)`,
    hoverSupported: `@media(hover: hover) and (pointer: fine)`,
  },
};

export { theme };
export type Theme = typeof theme;
