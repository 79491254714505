import React from 'react';

export const Tv4LogoIcon = ({ className, size = 40 }: { className?: string; size?: number }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20 40C31.0456 40 40 31.0488 40 20.0072C40 8.96562 31.0456 0 20 0C8.95438 0 0 8.95519 0 20.0072C0 31.0592 8.95038 40 20 40Z"
      fill="#FF3334"
    />
    <path
      d="M20.9219 26.5659H15.7358L20.9219 18.8683V26.5659ZM28.95 26.5499H26.2437V3.4579C26.2444 3.36813 26.216 3.28056 26.1627 3.2084C26.1095 3.13625 26.0343 3.08342 25.9485 3.0579C25.864 3.03058 25.7729 3.03227 25.6895 3.0627C25.6061 3.09312 25.5352 3.15055 25.4881 3.2259L6.75733 31.0027C6.71506 31.0646 6.69096 31.1372 6.68784 31.2121C6.68476 31.2872 6.70277 31.3615 6.73981 31.4267C6.7735 31.4938 6.82515 31.5503 6.88897 31.5897C6.95275 31.6291 7.02628 31.6499 7.10122 31.6499H20.9219V34.7787C20.9243 34.8874 20.9686 34.9911 21.0455 35.0679C21.1224 35.1446 21.2259 35.1887 21.3344 35.1907H25.828C25.9369 35.1893 26.0411 35.1455 26.1186 35.0687C26.1962 34.9919 26.241 34.8879 26.2437 34.7787V31.6499H28.95C29.0048 31.6506 29.0592 31.6401 29.1099 31.6192C29.1607 31.5983 29.2067 31.5674 29.2451 31.5283C29.2836 31.4891 29.3139 31.4426 29.334 31.3914C29.3542 31.3404 29.3639 31.2857 29.3625 31.2307V26.9667C29.3634 26.912 29.3533 26.8577 29.3329 26.807C29.3125 26.7562 29.2822 26.71 29.2438 26.6712C29.2054 26.6324 29.1595 26.6017 29.1091 26.5808C29.0587 26.56 29.0045 26.5494 28.95 26.5499Z"
      fill="white"
    />
  </svg>
);
