import styled from 'styled-components';

import { MenuTopSection } from 'components/menu';
import { menuTransition } from 'styles/mixins/menuTransition';
import { PRIMARY_TAB_MENU_HEIGHT_MOBILE, theme } from 'styles/theme';

const menuNotHiddenMobile = `&:has(~ ${MenuTopSection}[data-is-hidden="false"])`;

export const Section = styled('section')`
  width: 100%;
  position: fixed;
  bottom: 0;
  flex-direction: column;
  justify-content: stretch;
  display: grid;
  grid-auto-rows: 56px;
  grid-template-columns: 1fr;
  z-index: ${theme.zIndex.menu};
  ${menuTransition}
  ${theme.mq.mobile} {
    transform: translateY(0);
    ${menuNotHiddenMobile} {
      transform: translateY(-${PRIMARY_TAB_MENU_HEIGHT_MOBILE}px);
    }
  }
`;
