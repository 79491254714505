import styled, { css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { theme } from 'styles/theme';

export const MessageBar = styled.div`
  background-color: ${colors.black[100]};

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100vw - 120px);
    display: block;
    overflow: hidden;
    ${({ theme }) => theme.mq.tablet} {
      max-width: calc(100vw - 500px);
    }
  }

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
  }
`;

export const ServiceMessageText = styled(Typography)`
  display: flex;
  flex-direction: column;

  a {
    color: white;
    text-decoration: underline !important;
  }

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
  }
`;

const InfoBarWrapper = css`
  display: flex;
  align-items: center;
  padding: 0 ${sizeUnits[16]};
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.mq.tablet} {
    padding: 0 ${sizeUnits[40]};
  }
`;

export const Alert = styled.div`
  ${InfoBarWrapper}
  background: ${colors.red.tv4};
  color: ${colors.white[100]};

  svg {
    color: ${colors.white[100]};
  }
  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
  }
`;

export const Unsupported = styled.div`
  ${InfoBarWrapper}
  background: ${colors.white[20]};
  color: ${colors.white[100]};

  a,
  svg {
    color: ${colors.white[100]};
  }
`;

export const CenteredMessage = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
    max-width: ${theme.maxContentWidth - 80}px;
  }
`;

export const I = styled.i`
  margin-right: 10px;
`;

export const CloseButton = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
