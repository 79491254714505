import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import type { KilkayaPositionData, TGroupId } from '@news/tracking';
import { getItemData, getItemKilkayaData } from '@news/tracking';

import { ConsentGroup, useConsentContext } from 'contexts/ConsentContext';
import { usePageContext } from 'contexts/PageContext';

import { pushItemEvent, pushKilkayaClickEvent } from './item';

export type PositionData = {
  index: number;
  groupId: TGroupId;
  kilkayaPositionId?: KilkayaPositionData['positionName'];
};

type ItemTrackerProps = React.PropsWithChildren<{
  item: Parameters<typeof getItemData>[0];
  positionData?: PositionData;
  disableClickTracking?: boolean;
  disableImpressionTracking?: boolean;
  className?: string;
  as?: React.ElementType;
}>;

interface TClickTrackerContext {
  sendItemClickEvent: () => void;
}

const ClickTrackerContext = createContext<TClickTrackerContext | undefined>(undefined);

export const ItemTracker = ({
  children,
  item,
  positionData,
  disableImpressionTracking = false,
  disableClickTracking = false,
  as = 'div',
  className,
}: ItemTrackerProps) => {
  const [trackedImpression, setTrackedImpression] = useState(false);
  const { pageData } = usePageContext();
  const { activeGroups } = useConsentContext();
  const itemRef = useRef<HTMLElement>(null);

  const itemData = useMemo(() => {
    return getItemData(item);
  }, [item]);

  const kilkayaData = useMemo(() => {
    return getItemKilkayaData(item);
  }, [item]);

  useEffect(() => {
    if (disableImpressionTracking || trackedImpression || !activeGroups.includes(ConsentGroup.Performance)) {
      return;
    }
    const observer = new IntersectionObserver(([entry], observer) => {
      if (!entry?.isIntersecting || !pageData) {
        return;
      }
      pushItemEvent({
        event: 'item_impression',
        ...pageData,
        item_group_id: positionData?.groupId ?? null,
        item_group_index: positionData?.index.toString() ?? null,
        ...itemData,
      });
      setTrackedImpression(true);
      observer.unobserve(entry.target);
    });

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [activeGroups, disableImpressionTracking, itemData, pageData, positionData, trackedImpression]);

  const sendItemClickEvent = useCallback(() => {
    if (!pageData) {
      return;
    }
    pushItemEvent({
      event: 'item_click',
      item_group_id: positionData?.groupId ?? null,
      item_group_index: positionData?.index.toString() ?? null,
      ...pageData,
      ...itemData,
    });
    if (!kilkayaData) {
      return;
    }
    if (activeGroups.includes(ConsentGroup.Performance)) {
      pushKilkayaClickEvent({
        positionName: positionData?.kilkayaPositionId,
        row: positionData?.index,
        pagePath: pageData.page_path,
        pageTitle: pageData.page_title,
        pageSection: pageData.page_feed ?? undefined,
        pageType: pageData.page_type,
        ...kilkayaData,
      });
    }
  }, [positionData, pageData, itemData, kilkayaData, activeGroups]);

  const trackItemClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (disableClickTracking) {
        return;
      }
      sendItemClickEvent();
    },
    [disableClickTracking, sendItemClickEvent]
  );

  const contextValue: TClickTrackerContext = useMemo(() => ({ sendItemClickEvent }), [sendItemClickEvent]);

  const Component = as;
  return (
    <ClickTrackerContext.Provider value={contextValue}>
      <Component className={className} ref={itemRef} onClick={trackItemClick}>
        {children}
      </Component>
    </ClickTrackerContext.Provider>
  );
};

export const useClickTracker = (): TClickTrackerContext => {
  const context = useContext(ClickTrackerContext);
  if (!context) {
    throw new Error('useClickTracker must be used within a ClickTrackerContextProvider');
  }
  return context;
};
