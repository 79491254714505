import type { ImageConversionArgs } from '../imageProxy';
import { ImageProxy } from '../imageProxy';

interface Props {
  imageSrc: string;
  sizes: number[];
  additionalImageParams?: ImageConversionArgs;
}

// Generates srcset property for img tags
export const generateSrcSet = ({ imageSrc, sizes, additionalImageParams = {} }: Props): string => {
  return imageSrc.length > 0
    ? sizes
        .map((size) => `${ImageProxy.url({ url: imageSrc, width: size, ...additionalImageParams })} ${size}w`)
        .toString()
    : '';
};
