import type { RelatedArticleFieldsFragment } from '@news/gql';
import type { NewsEntry } from '@news/lib';

export type PageType = 'feed' | 'article' | 'video' | 'breaking-news' | 'archive' | 'other';

export const getFeedItemPageType = (itemType: NewsEntry | RelatedArticleFieldsFragment) => {
  switch (itemType.__typename) {
    case 'Article':
    case 'RelatedArticle':
      return 'article';
    case 'VideoAsset':
      return 'video';
  }
};

export type PageData = {
  /** The path of the page, e.g. /nyhetsmorgon */
  page_path: string;
  /** The ID of the entity as it is in Contentful, or hardcoded unique ID in kebab-case */
  page_id: string;
  /** The title of the page */
  page_title: string;
  /** Type of page, e.g. feed, article, breakingNews, */
  page_type: PageType;
  /** The feed associated with the page, null if no feed is associated */
  page_feed: string | null;
  /** This is the tags that this page belongs to */
  page_tags: string | null;
};

export type PageEvent = PageData & {
  event: 'page';
};

export const StaticPageId = {
  BreakingNews: 'breaking-news',
  AllFeeds: 'alla-floden',
  AllPrograms: 'alla-program',
  Schedule: 'schedule',
  // For dates it should be archive-YYYY-MM-DD
  Archive(date?: string) {
    if (!date) return 'archive';
    return `archive-${date}`;
  },
  Error404: 'error-404',
  Error500: 'error-500',
};
