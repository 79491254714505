import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { PRIMARY_TAB_MENU_HEIGHT_MOBILE, theme } from 'styles/theme';

export const StyledFooter = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${theme.colors.beige.dark};

  * {
    color: ${colors.black[80]};
  }

  padding-bottom: ${PRIMARY_TAB_MENU_HEIGHT_MOBILE}px;
  ${theme.mq.tablet} {
    padding-bottom: 0;
  }
`;

export const EndSection = styled.div`
  width: 100%;
  padding: 60px ${sizeUnits[16]} ${sizeUnits[40]} ${sizeUnits[16]};
  position: relative;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -45%);
`;

export const EndSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  ul li {
    text-align: center;
    list-style: none;
  }

  p,
  a {
    margin: 0;
    text-align: left;
  }
`;

export const BackToTopButton = styled.button`
  padding: ${sizeUnits[20]} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  span[role='img'] {
    position: absolute;
    right: 0;
    top: ${sizeUnits[16]};
  }
  ${({ theme }) => theme.mq.tablet} {
    justify-content: center;

    span[role='img'] {
      position: static;
      height: ${sizeUnits[24]};
    }
  }
`;

export const StyledLink = styled.a`
  color: ${colors.black[80]};
`;
