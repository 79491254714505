import React from 'react';

interface Props {
  size?: number;
}

export const ExternalLinkIcon = ({ size = 20 }: Props) => (
  <svg
    role="img"
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    opacity="0.7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Extern länk</title>
    <path
      d="M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.87507 3.58579 6.5C3.96086 6.12493 4.46957 6 5 6H11"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 3H21V9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 14L21 3" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
