export const IMAGE_PROXY_URL = 'https://imageproxy.a2d.tv';

export const TEST_ID = {
  newsfeed: 'newsfeed',
  newsFeedItem: 'newsFeedItem',
  feedContentContainer: 'feedContentContainer',
  expanded: 'expanded',
  collapsed: 'collapsed',
  body: 'body',
  sortPrioritized: 'sortPrioritized',
  sortPublished: 'sortPublished',
  allFeedsHeading: 'allFeedsHeading',
  allFeedsItem: 'allFeedsItem',
  programFeedItem: 'programFeedItem',
  errorPage: 'errorPage',
  topMenu: 'topMenu',
  footer: 'footer',
  feedItemTitle: 'feedItemTitle',
  liveReport: 'liveReport',
  lead: 'lead',
  heroTitle: 'heroTitle',
};
