import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const FilledPlayIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg style={cssSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 4.86887C6 4.07017 6.89015 3.59378 7.5547 4.03682L18.2519 11.1683C18.8457 11.5641 18.8457 12.4366 18.2519 12.8324L7.5547 19.9639C6.89015 20.4069 6 19.9305 6 19.1318V4.86887Z"
      fill={color}
    />
  </svg>
);
