import React from 'react';

export const Tv4NewsLogoIcon = ({ className }: { className?: string }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1303.62 213.24"
    className={className}
  >
    <g>
      <g>
        <g>
          <path
            fill="#ff3334"
            d="m79.64,186.27c43.99,0,79.66-35.65,79.66-79.63S123.63,26.97,79.64,26.97,0,62.64,0,106.64s35.65,79.63,79.64,79.63"
          />
          <path
            fill="#fff"
            d="m83.54,132.77h-20.71l20.71-30.65v30.65Zm32.07-.07h-10.81V40.74c0-.75-.48-1.36-1.18-1.58-.69-.21-1.43.03-1.83.67L26.98,150.43c-.35.51-.37,1.15-.08,1.69.28.54.85.9,1.43.9h55.2v12.46c0,.86.75,1.64,1.65,1.64h17.94c.92,0,1.66-.78,1.66-1.64v-12.46h10.81c.94,0,1.64-.75,1.64-1.68v-16.98c0-.92-.71-1.66-1.64-1.66"
          />
        </g>
        <rect fill="#2b2928" x="204.02" y="0" width="9.09" height="213.24" rx="4.51" ry="4.51" />
        <g>
          <path
            fill="#2b2928"
            d="m263.69,168.12V45.12h19.43l62.11,86.05V45.12h21.17v123.01h-19.6l-61.94-86.05v86.05h-21.17Z"
          />
          <path
            fill="#2b2928"
            d="m428.81,168.12v-51.7l-41.46-71.31h24.46l28.11,50.66,27.76-50.66h23.59l-41.29,72.52v50.49h-21.17Z"
          />
          <path
            fill="#2b2928"
            d="m512.21,168.12V45.12h21.17v50.83h53.61v-50.83h21.17v123.01h-21.17v-52.92h-53.61v52.92h-21.17Z"
          />
          <path
            fill="#2b2928"
            d="m639.42,168.12V45.12h76.51v19.6h-55.17v32.1h47.54v19.6h-47.54v32.1h55.86v19.6h-77.2Z"
          />
          <path fill="#2b2928" d="m772.49,168.12v-103.4h-35.74v-19.6h92.65v19.6h-35.74v103.4h-21.17Z" />
          <path
            fill="#2b2928"
            d="m851.62,168.12V45.12h76.51v19.6h-55.17v32.1h47.54v19.6h-47.54v32.1h55.86v19.6h-77.2Z"
          />
          <path
            fill="#2b2928"
            d="m954.82,168.12V45.12h47.36c22.38,0,40.25,17.52,40.25,39.21,0,15.09-9.02,28.45-22.21,34.87l24.64,48.92h-23.07l-21.69-44.93h-24.12v44.93h-21.17Zm21.17-64.54h24.98c10.76,0,19.95-9.02,19.95-19.6s-9.2-19.26-19.95-19.26h-24.98v38.86Z"
          />
          <path
            fill="#2b2928"
            d="m1068.93,168.12V45.12h19.43l62.11,86.05V45.12h21.17v123.01h-19.6l-61.94-86.05v86.05h-21.17Z"
          />
          <path
            fill="#2b2928"
            d="m1189.63,168.12l47.88-123.01h18.74l47.36,123.01h-22.21l-11.97-31.58h-45.63l-11.8,31.58h-22.38Zm41.29-50.66h31.4l-15.61-41.81-15.79,41.81Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
