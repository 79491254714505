import type { FactBoxNodeDataFieldsFragment, NodeDataFieldsFragment, NodeDataUnionFieldsFragment } from '@news/gql';

/** @public */
export enum NodeType {
  Document = 'Document',
  Text = 'Text',
  Bold = 'Bold',
  Italic = 'Italic',
  Paragraph = 'Paragraph',
  UnorderedList = 'UnorderedList',
  OrderedList = 'OrderedList',
  ListItem = 'ListItem',
  Heading1 = 'Heading1',
  Heading2 = 'Heading2',
  Heading3 = 'Heading3',
  Heading4 = 'Heading4',
  Heading5 = 'Heading5',
  Heading6 = 'Heading6',
  HyperLink = 'HyperLink',
  VideoPlayer = 'VideoPlayer',
  ImageSlider = 'ImageSlider',
  Image = 'Image',
  HR = 'HR',
  Blockquote = 'Blockquote',
  Underline = 'Underline',
  Code = 'Code',
  YouTubePlayer = 'YouTubePlayer',
  FactBox = 'FactBox',
  ExtendedVideoAsset = 'ExtendedVideoAsset',
  Poll = 'Poll',
  EditorialBanner = 'EditorialBanner',
  Ad = 'Ad',
  Table = 'Table',
  TableRow = 'TableRow',
  TableCell = 'TableCell',
  TableHeaderCell = 'TableHeaderCell',
  SportStandingsTable = 'SportStandingsTable',
  BalanceOfPower = 'BalanceOfPower',
}

/** @public */
export enum BaseTextType {
  Text = NodeType.Text,
  Bold = NodeType.Bold,
  Italic = NodeType.Italic,
  Underline = NodeType.Underline,
  Code = NodeType.Code,
}

/** @public */
export enum HeadingType {
  Heading1 = NodeType.Heading1,
  Heading2 = NodeType.Heading2,
  Heading3 = NodeType.Heading3,
  Heading4 = NodeType.Heading4,
  Heading5 = NodeType.Heading5,
  Heading6 = NodeType.Heading6,
}

/**
 * @public
 * Set of node types that will be encapsulated within their own blocks, i.e. separated by newlines,
 * such as paragraphs, headings, lists, images, etc.
 */
export enum BlockNodeType {
  Paragraph = NodeType.Paragraph,
  Blockquote = NodeType.Blockquote,
  UnorderedList = NodeType.UnorderedList,
  OrderedList = NodeType.OrderedList,
  ListItem = NodeType.ListItem,
  Heading1 = NodeType.Heading1,
  Heading2 = NodeType.Heading2,
  Heading3 = NodeType.Heading3,
  Heading4 = NodeType.Heading4,
  Heading5 = NodeType.Heading5,
  Heading6 = NodeType.Heading6,
  VideoPlayer = NodeType.VideoPlayer,
  ImageSlider = NodeType.ImageSlider,
  Image = NodeType.Image,
  HR = NodeType.HR,
  Code = NodeType.Code,
  YouTubePlayer = NodeType.YouTubePlayer,
  FactBox = NodeType.FactBox,
  ExtendedVideoAsset = NodeType.ExtendedVideoAsset,
  Table = NodeType.Table,
  SportStandingsTable = NodeType.SportStandingsTable,
  BalanceOfPower = NodeType.BalanceOfPower,
}

export type DocumentNode = {
  nodeType: NodeType.Document;
  children: Node[];
};

export type Node = {
  id?: string;
  nodeType: NodeType;
  children?: Node[];
  content?: string;
};

export type NodeData = (NodeDataFieldsFragment | FactBoxNodeDataFieldsFragment)[];

export type ArticleBody<T extends NodeDataUnionFieldsFragment['__typename']> = Extract<
  NodeDataUnionFieldsFragment,
  { __typename: T }
>;
