import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { StaticItemId, appendUtmTags } from '@news/tracking';

import { Typography } from 'components/Typography';
import { BigArrow } from 'components/icons';
import { fifthElementBackground } from 'styles/mixins/fifthElementBackground';
import { theme } from 'styles/theme';

const TARGET_URL = appendUtmTags('https://www.tv4play.se', 'RedBanner');

export const TV4PlayButton = () => {
  return (
    <StyledItemTracker
      item={{
        item_id: StaticItemId.RedBanner,
        item_title: 'Till TV4 Play / TV4 Play',
        item_type: 'RedBanner',
        item_target_url: TARGET_URL,
        item_tags: null,
      }}
    >
      <TV4PlayLink as="a" variant="CTA" href={TARGET_URL} target="_blank">
        <DesktopText>Till TV4 Play</DesktopText>
        <MobileText>TV4 Play</MobileText>
        <StyledBigArrow />
      </TV4PlayLink>
    </StyledItemTracker>
  );
};

const StyledItemTracker = styled(ItemTracker)`
  display: flex;
`;

const DesktopText = styled.span`
  display: none;

  ${theme.mq.tablet} {
    display: inline;
  }
`;

const MobileText = styled.span`
  display: inline;

  ${theme.mq.tablet} {
    display: none;
  }
`;

const TV4PlayLink = styled(Typography)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px ${sizeUnits[8]};
  border-radius: ${sizeUnits[4]};
  white-space: nowrap;
  ${fifthElementBackground({ backgroundWidth: 200 })};

  ${theme.mq.tablet} {
    ${fifthElementBackground({ backgroundWidth: 500 })};
    height: ${sizeUnits[40]};
    padding: ${sizeUnits[8]} ${sizeUnits[16]};
    border-radius: ${sizeUnits[8]};
  }
`;

const StyledBigArrow = styled(BigArrow)`
  width: 16px;
  height: 16px;

  ${theme.mq.tablet} {
    width: 24px;
    height: 24px;
  }
`;
