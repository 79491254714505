import NextLink from 'next/link';
import type { HTMLAttributes } from 'react';
import React from 'react';
import { ItemTracker } from 'tracking/ItemTracker';

import type { LinkFieldsFragment } from '@news/gql';
import type { TGroupId } from '@news/tracking';
import { appendUtmTags } from '@news/tracking';

interface LinkComponentProps extends HTMLAttributes<HTMLAnchorElement> {
  link: LinkFieldsFragment;
  current?: boolean;
  groupId: TGroupId;
  index: number;
  children?: React.ReactElement;
  className?: string;
}

export const getLinkHref = (link: LinkFieldsFragment): string | null => {
  if (!link.href) return null;

  switch (link.targetType) {
    case 'InternalLink':
    case 'Newsfeed':
      return link.href;
    case 'Article':
      return `/artikel/${link.id}/${link.href}`;
    case 'ExternalLink':
      return appendUtmTags(link.href, 'Link', false);
    default:
      return null;
  }
};

export const LinkComponent = ({ groupId, index, link, current, ...rest }: LinkComponentProps) => {
  const href = getLinkHref(link);
  if (!href) return null;

  const getLinkComponent = () => {
    switch (link.targetType) {
      case 'InternalLink':
      case 'Newsfeed':
        return <InternalLink href={href} current={current} {...rest} />;
      case 'Article':
        return <ArticleLink href={href} current={current} {...rest} />;
      case 'ExternalLink':
        return <ExternalLink href={href} {...rest} />;
      default:
        return null;
    }
  };

  return (
    <ItemTracker
      disableImpressionTracking
      item={link}
      positionData={{
        groupId: groupId,
        index,
      }}
    >
      {getLinkComponent()}
    </ItemTracker>
  );
};

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  current?: boolean;
  children?: React.ReactElement;
  className?: string;
}

const InternalLink = ({ className, children, href, current, ...rest }: LinkProps) => {
  return (
    <NextLink className={className} aria-current={current ? 'page' : 'false'} href={href} {...rest}>
      {children}
    </NextLink>
  );
};

const ArticleLink = ({ className, children, href, current, ...rest }: LinkProps) => {
  return (
    <NextLink className={className} aria-current={current ? 'page' : 'false'} href={href} {...rest}>
      {children}
    </NextLink>
  );
};

const ExternalLink = ({ className, children, href, ...rest }: LinkProps) => {
  return (
    <a className={className} href={href} target="_blank" {...rest}>
      {children}
    </a>
  );
};
