import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';

import { createApolloClient } from './apolloClient';

export const ApolloClientProvider = ({ children }: React.PropsWithChildren) => {
  const apolloClient = useMemo(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }
    return createApolloClient();
  }, []);

  /**
   * On the server, the ApolloClient is created in _document.tsx.
   */
  if (!apolloClient) {
    return <>{children}</>;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
