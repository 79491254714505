import React from 'react';

interface Props {
  className?: string;
  size?: number;
}

export const EntertainmentIcon = ({ className, size = 24 }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size + 1}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.99988 14.289C7.99988 14.289 9.49988 16.289 11.9999 16.289C14.4999 16.289 15.9999 14.289 15.9999 14.289M8.99988 10.789C8.99988 10.789 8.43738 10.289 7.49988 10.289C6.56238 10.289 5.99988 10.789 5.99988 10.789M17.9999 10.789C17.9999 10.789 17.4374 10.289 16.4999 10.289C15.5624 10.289 14.9999 10.789 14.9999 10.789M21.9999 12.289C21.9999 17.8118 17.5227 22.289 11.9999 22.289C6.47703 22.289 1.99988 17.8118 1.99988 12.289C1.99988 6.76615 6.47703 2.289 11.9999 2.289C17.5227 2.289 21.9999 6.76615 21.9999 12.289Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
