import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '../constants';

export const dateString = (date: Date = new Date(), options?: Intl.DateTimeFormatOptions): string => {
  return date.toLocaleString(DEFAULT_LOCALE, { timeZone: DEFAULT_TIMEZONE, ...options });
};

/**
 * Gets the weekday in the default locale and timezone, e.g. "onsdag"
 */
export const getWeekday = (date: Date = new Date(), format: Intl.DateTimeFormatOptions['weekday'] = 'long'): string => {
  // In React Native this returns strings like Söndag, 2023-08-26..., so
  // TODO(axel): Remove React Native fix when the React Native have solved this
  return dateString(date, { weekday: format }).split(',')[0] as string;
};

/**
 * Gets the month in the default locale and timezone, e.g. "maj"
 */
export const getMonth = (date: Date = new Date(), format: Intl.DateTimeFormatOptions['month'] = 'long'): string => {
  return dateString(date, { month: format });
};

/**
 * Gets the year in the default locale and timezone, e.g. 2023
 */
export const getYear = (date: Date = new Date()): number => {
  return parseInt(dateString(date, { year: 'numeric' }));
};

export const getDay = (date: Date = new Date()): number => {
  return parseInt(dateString(date, { day: 'numeric' }));
};

/**
 * Gets the current time in <hour>:<minute> format, e.g. 13:37
 */
export const getHourMinuteTime = (date: Date = new Date()): string => {
  return dateString(date, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

/**
 * Gets the current hour, e.g. 6
 */
export const getHour = (date: Date): number => {
  return parseInt(dateString(date, { hour: 'numeric' }));
};
