export const parseKilkayaValue = (value: string | string[] | number | boolean): string => {
  if (value === undefined) {
    // Empty value
    return '';
  }
  if (Array.isArray(value)) {
    // For Kilkaya, array entries are delimited by `|`
    return value.join('|');
  }
  if (typeof value === 'boolean') {
    return value ? '1' : '0';
  }
  return value.toString();
};
