import localFont from 'next/font/local';
import { createGlobalStyle } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { normalize } from './mixins/normalize';
import { theme } from './theme';

const fyranSans = localFont({
  src: [
    { path: '../public/assets/fonts/FyranSans-Medium.woff2', weight: '500', style: 'normal' },
    { path: '../public/assets/fonts/FyranSans-Regular.woff2', weight: '400', style: 'normal' },
    //Italic-bold is currently not supported as text which is Italic-bold will be handled as italic by the graph
    { path: '../public/assets/fonts/FyranSans-MediumItalic.woff2', weight: '500', style: 'italic' },
    { path: '../public/assets/fonts/FyranSans-RegularItalic.woff2', weight: '400', style: 'italic' },
  ],
});

const GlobalStyle = createGlobalStyle`
  ${normalize};
  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    font-family: ${fyranSans.style.fontFamily};
    background: ${theme.colors.pink.light};
    color: ${colors.black[100]};
    font-synthesis-weight: none;
    font-synthesis-style: none;
    overflow-anchor: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    #ot-sdk-btn-floating {
      display: none !important;
    }
  }

  a {
    color: ${colors.red.tv4};
    text-decoration: none;
  }

  @media (hover: hover) {
    a:hover {
      text-decoration: underline;
    }
  }

  button:focus,
  a:focus {  
    outline: none;
    opacity: 0.6;
  }

  @supports selector(:focus-visible) {  
    button:focus,
    a:focus {  
      outline: none;
      opacity: 1;
    }
  } 

  button:focus-visible,
  a:focus-visible {  
    outline: none;
    opacity: 0.6;
  }

  a:active {
    outline: none;
  }

  body.block-scrolling {
    height: 100%;
    overflow: hidden;
  }

  p {
    font-size: ${sizeUnits[16]};
    line-height: ${24 / 16};
  }
  
  :where(button) {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    :active {
      outline: none;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .discrete-text {
    font-size: 14px;
    color: #000;
    opacity: 0.6;
  }

  .margin-0 {
    margin: 0;
  }

  .tabular-nums {
    font-variant-numeric: tabular-nums;
    letter-spacing: -0.02em;
  }

  time {
    font-variant-numeric: tabular-nums;
    letter-spacing: initial;
  }

  .position-relative {
    position: relative;
  }

  .inherit-all {
    all: inherit;
  }

  .text-align-left {
    text-align: left;
  }

  .full-width {
    width: 100%;
  }
`;

export { GlobalStyle };
