/**
 * Since URLSearchParams.set is not implemented in React Native, we can use this function instead to achieve its behavior.
 */
export const setURLParameters = (url: string, params: Record<string, string>): string => {
  const fragmentSplit = url.split('#');
  const baseURL = fragmentSplit[0] as string;
  const fragment = fragmentSplit[1] ? `#${fragmentSplit[1]}` : '';

  const splitURL = baseURL.split('?');
  const baseWithoutQuery = splitURL[0];
  const searchParams = splitURL[1] || '';

  const paramsArray = searchParams.split('&').filter((param) => param);
  const previousParams = paramsArray.reduce<Record<string, string>>((prev, curr) => {
    const [paramKey, paramValue] = curr.split('=') as [string, string];
    return {
      ...prev,
      [decodeURIComponent(paramKey)]: decodeURIComponent(paramValue),
    };
  }, {});

  const newParams = { ...previousParams, ...params };

  const newSearchParams = Object.entries(newParams)
    .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
    .join('&');

  return `${baseWithoutQuery}?${newSearchParams}${fragment}`;
};
