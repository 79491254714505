import React from 'react';

import { cssSize } from './iconUtils';

export const RightSliderArrowIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(15, 16)}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8L14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L14 8ZM0 7L14 7L14 9L0 9L0 7ZM13.2929 8.70711L6.29289 1.70711L7.70711 0.292893L14.7071 7.29289L13.2929 8.70711ZM14.7071 8.70711L7.70711 15.7071L6.29289 14.2929L13.2929 7.29289L14.7071 8.70711Z"
      fill="#FF3334"
    />
  </svg>
);
