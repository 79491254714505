export const getStorageKey = (storage: Storage, key: string): string | null => {
  if (!storage) return null;

  try {
    const val = storage.getItem(key);
    return !val ? null : val;
  } catch (e: unknown) {
    console.log(e instanceof Error ? e.message : `Could not get storage key, ${storage}`);
    return null;
  }
};
