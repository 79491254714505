import { type EntryProps } from 'contentful-management';

export enum EntityState {
  Draft = 'draft',
  Changed = 'changed',
  Published = 'published',
  Archived = 'archived',
  Unknown = 'unknown',
}

//Docs: https://www.contentful.com/developers/docs/tutorials/general/determine-entry-asset-state/
export function getEntityState(entity: EntryProps) {
  if (!entity.sys.publishedVersion) {
    return EntityState.Draft;
  }

  if (entity.sys.version >= entity.sys.publishedVersion + 2) {
    return EntityState.Changed;
  }

  if (entity.sys.version === entity.sys.publishedVersion + 1) {
    return EntityState.Published;
  }

  if (entity.sys.archivedVersion) {
    return EntityState.Archived;
  }

  return EntityState.Unknown;
}
