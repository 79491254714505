import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { useMenuQuery } from '@news/gql';
import { BURGER_MENU_ID } from '@news/lib';

import { Typography } from 'components/Typography';
import { BURGER_MENU_LINK_GROUPS_FALLBACK } from 'components/menu/constants';
import { isClient } from 'lib/helpers';

import { BurgerMenuSection } from './BurgerMenuSection';

export const BurgerMenuLinkGroups = () => {
  const { data, loading, error } = useMenuQuery({
    variables: {
      id: BURGER_MENU_ID,
    },
  });

  const isErrorOnServer = error && !isClient;

  // If an error occurs on the server, it should return the loading-state
  // as the query is going to be rerun och the client. Otherwise there will be a hydration error.
  if (loading || isErrorOnServer) return null;

  const burgerMenuLinkGroups = error ? BURGER_MENU_LINK_GROUPS_FALLBACK : data?.menu?.groups;
  if (!burgerMenuLinkGroups) return null;

  return (
    <BurgerSectionsWrapper>
      {burgerMenuLinkGroups.map((group, index) => (
        <BurgerSectionWrapper key={group.id}>
          {index === 2 && <SectionLabel variant="title3-strong">Mer om TV4</SectionLabel>}
          <BurgerMenuSection sectionIndex={index} group={group} />
        </BurgerSectionWrapper>
      ))}
    </BurgerSectionsWrapper>
  );
};

const BurgerSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${sizeUnits[16]};
  padding-top: 0;
`;

const SectionLabel = styled(Typography)`
  margin-bottom: ${sizeUnits[16]};
`;

const BurgerSectionWrapper = styled.div`
  &:not(:last-child) {
    padding-bottom: ${sizeUnits[16]};
  }

  &:not(:first-child) {
    padding-top: ${sizeUnits[16]};
  }

  &:nth-child(1) {
    border-bottom: 1px solid ${colors.gray.light};
  }
`;
