import React from 'react';

import { cssSize } from './iconUtils';

export const KeyboardRightArrowIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(8, 12)}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.296875 10.59L4.87687 6L0.296875 1.41L1.70687 0L7.70687 6L1.70687 12L0.296875 10.59Z" fill="#616161" />
  </svg>
);
