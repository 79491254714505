import { FeedItemType } from '@news/gql';

export interface PageDetails {
  page: string;
  itemPath: (id: string, slug?: string) => string;
  type?: FeedItemType | 'BreakingNews';
}

/**
 * All item pages have a format of <prefix>/<id>/<slug?>. This generic function used to create item paths for a pre-given prefix.
 */
export const toItemPathFunction = (prefix: string) => (id: string, slug?: string) => {
  return `${prefix}/${id}${slug ? `/${slug}` : ''}`;
};

export const articlePageDetails: PageDetails = {
  page: '/',
  itemPath: toItemPathFunction('/artikel'),
  type: FeedItemType.Article,
};
export const relatedArticlePageDetails: PageDetails = {
  page: '/',
  itemPath: toItemPathFunction('/artikel'),
  type: FeedItemType.RelatedArticle,
} as const;

export const videoAssetPageDetails: PageDetails = {
  page: '/',
  itemPath: toItemPathFunction('/klipp/va'),
  type: FeedItemType.VideoAsset,
};

export const breakingNewsPageDetails: PageDetails = {
  page: '/just-nu',
  itemPath: toItemPathFunction('/just-nu'),
  type: 'BreakingNews',
};
