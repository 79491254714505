import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

import introspectionResult from '@news/gql';

import { isBrowser, mergeFeed, mergeLatestBreakingNews, persistedQueryhttpLink } from './helpers';

type TProgramRow = { nid: string };

const windowApolloState = isBrowser && window.__NEXT_DATA__?.props?.apolloState;

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: persistedQueryhttpLink(),
    name: 'web',
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        PaginatedNewsFeed: {
          fields: {
            feed: {
              keyArgs: false,
              merge: mergeFeed,
            },
          },
        },
        Query: {
          fields: {
            latestBreakingNews: {
              keyArgs: false,
              merge: mergeLatestBreakingNews,
            },
            sportResultsByLeague: {
              keyArgs: ['leagueId', 'resultsFilter', 'sortDateOrder'],
              merge(_, incoming) {
                return incoming;
              },
            },
          },
        },
      },
      possibleTypes: introspectionResult.possibleTypes,
      dataIdFromObject(responseObject) {
        // Program and HeroProgram have a "nid", not an "id". The cache record must have a unique identifier.
        switch (responseObject.__typename) {
          case 'Program':
            return `Program:${(responseObject as TProgramRow).nid}`;
          case 'HeroProgram':
            return `HeroProgram:${(responseObject as TProgramRow).nid}`;
          case 'Link':
            return `Link:${(responseObject as { linkId: string }).linkId}`;
          default:
            return defaultDataIdFromObject(responseObject) ?? '';
        }
      },
    }).restore(windowApolloState),
  });
}
