import React from 'react';

import { cssSize } from './iconUtils';

export const KeyboardLeftArrowIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(8, 12)}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70313 1.41L3.12313 6L7.70313 10.59L6.29313 12L0.293126 6L6.29313 -1.23266e-07L7.70313 1.41Z"
      fill="#616161"
    />
  </svg>
);
