import _throttle from 'lodash.throttle';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isClient } from 'lib/helpers';
import { isFeedItemPage } from 'lib/helpers/isFeedItemPage';
import { SECONDARY_TAB_MENU_HEIGHT_MOBILE, TOPBAR_HEIGHT_MOBILE } from 'styles/theme';

import { useCurrentRoute } from './useCurrentRoute';

export const enum ScrollDirection {
  Up,
  Down,
}

export const useScrollDirection = (wait = 200) => {
  const currentRoute = useCurrentRoute();
  const router = useRouter();
  const [direction, setDirection] = useState(ScrollDirection.Up);
  const offsetY = useRef(isClient ? window.scrollY : 0);

  const updateDirection = useCallback(() => {
    const main = document.querySelector('main');
    const minOffset = main?.offsetTop || TOPBAR_HEIGHT_MOBILE + SECONDARY_TAB_MENU_HEIGHT_MOBILE;

    // Make sure that scrolling past the top on for example
    // iPhone does not trigger a "down" on the way down.
    setDirection((prevState) => {
      // On feed item pages, the top bar is static, so can ignore the min offset
      if (!isFeedItemPage(currentRoute.path) && window.scrollY < minOffset) {
        return ScrollDirection.Up;
      }
      const deltaY = window.scrollY - offsetY.current;
      if (deltaY === 0) {
        return prevState;
      }
      if (prevState === ScrollDirection.Down) {
        // Only update direction if we have scrolled more than 50px up
        return deltaY > -50 ? ScrollDirection.Down : ScrollDirection.Up;
      }
      // Only update direction if we have scrolled more than 25px down
      return deltaY > 25 ? ScrollDirection.Down : ScrollDirection.Up;
    });
    // Update current value after calculations
    offsetY.current = window.scrollY;
  }, [currentRoute.path]);

  useEffect(() => {
    const handleOnScroll = _throttle(updateDirection, wait);

    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, [wait, updateDirection]);

  useEffect(() => {
    const callback = () => {
      offsetY.current = window.scrollY;
      updateDirection();
    };
    // Reset scroll position when route changes
    router.events.on('routeChangeComplete', callback);
    return () => {
      router.events.off('routeChangeComplete', callback);
    };
  }, [router.events, updateDirection]);

  return direction;
};
