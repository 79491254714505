export const getHost = (): string => {
  switch (process.env.ENVIRONMENT) {
    case 'dev':
      return 'http://localhost:1337';
    case 'stage':
      return 'https://beta1.tv4-stage.se';
    default:
      return 'https://www.tv4.se/';
  }
};
