import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { StaticItemId } from '@news/tracking';

import { ScreenReader } from 'components/common';
import { Tv4NewsLogoIcon } from 'components/icons';
import { theme } from 'styles/theme';

const Logo = () => {
  return (
    <ItemTracker
      disableImpressionTracking
      item={{
        item_id: StaticItemId.Logo,
        item_type: 'Logo',
        item_target_url: '/',
        item_title: 'Logo',
        item_tags: null,
      }}
    >
      <a tabIndex={0} href="/">
        <ScreenReader>TV4 Nyheterna</ScreenReader>
        <StyledLogo />
      </a>
    </ItemTracker>
  );
};

const StyledLogo = styled(Tv4NewsLogoIcon)`
  display: block;
  width: 140px;
  ${theme.mq.tablet} {
    width: 170px;
  }
`;

export { Logo };
