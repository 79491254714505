import styled, { css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';

import { Typography } from 'components/Typography';
import { MAX_CONTENT_WIDTH, theme } from 'styles/theme';

export const ScreenReader = styled.span<{ $showOnFocus?: boolean }>`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  ${({ $showOnFocus }) =>
    $showOnFocus &&
    css`
      &:focus {
        width: unset;
        white-space: unset;
        position: unset;
        overflow: unset;
        height: unset;
        clip-path: unset;
        clip: unset;
      }
    `}
`;

const StyledSkipLinkTarget = styled('a')`
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  box-sizing: border-box;
  position: absolute;
  margin: 0;
  padding: 0;
  scroll-margin-top: 60px;
  color: black;
`;

export const SkipLinkTarget = () => (
  <StyledSkipLinkTarget href="#skip-link-target" id="skip-link-target">
    Början på sidans innehåll
  </StyledSkipLinkTarget>
);

export const RedButton = styled(Typography)`
  background-color: ${colors.red.tv4};
  border-radius: 6px;
  color: ${colors.white[100]};
  margin-bottom: 25px;
  padding: 10px 16px;
  text-align: center;
`;

export const MaxWidthLayout = (isWide?: boolean) => css`
  width: 100%;
  ${theme.mq.tablet} {
    max-width: ${isWide ? '100%' : `${MAX_CONTENT_WIDTH}px`};
    padding-left: ${isWide ? 0 : '40px'};
    padding-right: ${isWide ? 0 : '40px'};
    margin-left: auto;
    margin-right: auto;
  }
`;
