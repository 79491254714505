import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';

import { theme } from 'styles/theme';

export const MenuItemLink = styled.a<{
  $active: boolean;
  $hideOnMobile?: boolean;
}>`
  margin: 0;
  padding: 0 ${(props) => (props.$hideOnMobile ? 0 : theme.space.xsmall)};
  margin-left: ${(props) => (props.$hideOnMobile ? 0 : theme.space.medium)};
  display: ${(props) => (props.$hideOnMobile ? 'none' : 'inline-flex')};
  align-items: center;
  font-style: normal;
  font-size: ${theme.fontSizes.small};
  text-decoration: none;
  font-weight: 500;
  position: relative;
  color: ${colors.black[70]};
  height: 100%;

  ${(props) =>
    props.$active &&
    `
      color: ${colors.black[100]};
      font-weight: 700;

      &:after {
        content: '';
        height: 0px;
        position: absolute;
        bottom: 0;
        background-color: ${colors.black[100]};
        height: 4px;
        width: calc(100%);
        left: 0;
      }
    `}

  ${({ theme }) => theme.mq.tablet} {
    display: inline-flex;
    text-transform: initial;
    align-items: center;
    padding: 0 ${theme.space.small};
    margin-left: 0;
  }

  /* Hover should be active only on desktop, it causes confusion on tablet: the link gets underscore, but isn't active */
  ${theme.mq.desktop} {
    padding: 0 ${theme.space.large};

    ${(props) =>
      props.$active &&
      `
        color: ${colors.black[100]};
        font-weight: 500;
        `}

    &:hover {
      outline: none;
      text-decoration: none;
      color: ${colors.black[100]};

      &::before {
        opacity: 1;
        height: 3px;
      }
    }
  }
`;
