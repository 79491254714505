import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const IconUpdated = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1C19.4477 1 19 1.44772 19 2V7H14C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V2C21 1.44772 20.5523 1 20 1Z"
      fill={color}
    />
    <path
      d="M13.3932 3.10687C11.2463 2.80481 9.05935 3.20866 7.16192 4.25759C5.26448 5.30651 3.75934 6.94367 2.87326 8.9224C1.98719 10.9011 1.76819 13.1142 2.24927 15.2282C2.73034 17.3423 3.88543 19.2427 5.54048 20.6431C7.19553 22.0436 9.2609 22.8682 11.4254 22.9928C13.5899 23.1174 15.7362 22.5352 17.541 21.3338C19.3458 20.1325 20.7113 18.3772 21.4317 16.3323C21.6153 15.8114 21.3418 15.2403 20.8209 15.0568C20.3 14.8733 19.7289 15.1468 19.5454 15.6677C18.969 17.3036 17.8766 18.7079 16.4328 19.6689C14.989 20.63 13.2719 21.0958 11.5403 20.9961C9.80872 20.8965 8.15642 20.2367 6.83238 19.1164C5.50833 17.996 4.58427 16.4757 4.19941 14.7845C3.81455 13.0933 3.98975 11.3228 4.69861 9.73979C5.40746 8.15681 6.61158 6.84707 8.12953 6.00794C9.64748 5.1688 11.397 4.84571 13.1146 5.08736C14.8321 5.32901 16.4246 6.1223 17.652 7.3477C17.6592 7.35483 17.6664 7.36184 17.6738 7.36875H19.0541V5.92133C17.5212 4.39581 15.535 3.4082 13.3932 3.10687Z"
      fill={color}
    />
    <path
      d="M15.5676 10.8875C15.9984 11.233 16.0676 11.8624 15.7221 12.2933L12.6713 16.0976C12.2685 16.5998 11.5501 16.714 11.0115 16.3614L8.66033 14.8221C8.19826 14.5196 8.06891 13.8998 8.37142 13.4378C8.67392 12.9757 9.29374 12.8463 9.75581 13.1488L11.5371 14.315L14.1618 11.042C14.5074 10.6112 15.1367 10.542 15.5676 10.8875Z"
      fill={color}
    />
  </svg>
);
