export const nidToSlug = (nid: string): string => {
  if (!nid || nid === '') return '/';

  const nidAsSlug = nid.replace(/[å|ä]/g, 'a').replace(/ö/g, 'o');
  switch (nidAsSlug) {
    case 'nyheterna':
      return '/';
    case 'sporten':
      return '/sport';
    case 'fotbollsklipp':
      return '/sport';
    default:
      return `/${nidAsSlug}`;
  }
};
