import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { theme } from 'styles/theme';

export const ButtonBase = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${sizeUnits[8]};
  border-radius: ${sizeUnits[8]};

  ${theme.mq.tablet} {
    padding: ${sizeUnits[8]} ${sizeUnits[16]};
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  background-color: ${colors.gray.dark};
  color: ${colors.black[100]};
`;

export const SecondaryButton = styled(ButtonBase)`
  background-color: ${colors.red.tv4};
  color: ${colors.white[100]};
`;

export const SocialButton = styled.button`
  display: flex;
  width: ${sizeUnits[24]};
  height: ${sizeUnits[24]};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.gray.dark};
  color: ${colors.black[70]};
`;
