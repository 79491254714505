export enum HumanDate {
  TODAY = 'Idag',
  YESTERDAY = 'Igår',
  TOMORROW = 'Imorgon',
}

export interface ScheduleDay {
  date: string;
  text: string;
}

export enum BroadcastStatus {
  PAST_TENSE = 'pastTense',
  LIVE = 'live',
  UPCOMING = 'upcoming',
}
