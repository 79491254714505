import { useEffect, useState } from 'react';

import { cookieService as cookies } from 'lib/cookieService';
import { isClient } from 'lib/helpers';

import { CloseIcon, InfoIcon } from '../../icons';
import { Alert, CenteredMessage, CloseButton, I, MessageBar, ServiceMessageText } from '../styles';

interface IProps {
  id: string;
  title: string;
  message: string;
}

function ServiceMessageRow({ id, title, message }: IProps) {
  const [showBar, setShowBar] = useState(false);
  const name = `messageBar-${id}`;

  const handleClose = () => {
    cookies.set({ name, value: 'n', options: { expires: 1 } });
    setShowBar(false);
  };

  //eslint-disable-next-line
  const pattern = /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g;
  const result = '<a href="$2" title="$4" target="_blank">$1</a>';
  const parsedMessage = message.replace(pattern, result);

  useEffect(() => {
    if (!isClient) return;
    if (!cookies.get(name)) setShowBar(true);
  }, [name]);

  if (!showBar) return null;

  return (
    <MessageBar data-testid="service-message-bar">
      <Alert>
        <CenteredMessage>
          <I aria-hidden="true">
            <InfoIcon />
          </I>
          <ServiceMessageText as="div" variant="body2-strong">
            <b>{title}:&nbsp;</b>
            <span dangerouslySetInnerHTML={{ __html: parsedMessage }}></span>
          </ServiceMessageText>
          <CloseButton aria-label="Stäng meddelande" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </CenteredMessage>
      </Alert>
    </MessageBar>
  );
}

export { ServiceMessageRow };
