import type { FeedOriginFieldsFragment } from '@news/gql';

import type { TrackedItem } from './getItemData';

export const getTagsForItem = (item: TrackedItem): string | null => {
  if (!('__typename' in item)) {
    return null;
  }

  switch (item.__typename) {
    case 'Article':
    case 'RelatedArticle':
      return feedOriginToTags(item.feedOrigins);
    default:
      return null;
  }
};

export const feedOriginToTags = (feedOrigins: FeedOriginFieldsFragment[]): string | null => {
  const categories = feedOrigins.map((feed) => feed.slug);

  if (categories.length === 0) {
    return null;
  }

  return categories.join(';');
};
