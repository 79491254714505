import type { Dispatch, ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useReducer, useRef, useState } from 'react';

import { type VideoAssetFieldsFragment } from '@news/gql';

export enum ActionTypes {
  ADD_ITEMS,
  ADD_EMBEDDED,
}

type Action = {
  type: ActionTypes;
  items: VideoAssetWithParentFeaturedId[];
};

export type VideoAssetWithParentFeaturedId = VideoAssetFieldsFragment & { parentFeaturedId?: string };

interface IPlayNextContentContext {
  isNextContentMutedRef: React.MutableRefObject<boolean> | null;
  nowPlaying: VideoAssetFieldsFragment | null;
  getNextItem: (previousId: string) => VideoAssetWithParentFeaturedId | null;
  setPncList: Dispatch<Action>;
  setNowPlaying: Dispatch<VideoAssetFieldsFragment | null>;
}

const PlayNextContentContext = createContext<IPlayNextContentContext>({
  isNextContentMutedRef: null,
  nowPlaying: null,
  getNextItem: () => null,
  setPncList: () => {},
  setNowPlaying: () => {},
});

PlayNextContentContext.displayName = 'PlayNextContentContext';

const addEmbeddedVideos = (state: VideoAssetWithParentFeaturedId[], items: VideoAssetWithParentFeaturedId[]) => {
  const isAlreadyAdded = state.some((s) => s.id === items[0]?.id);
  if (isAlreadyAdded) return state;

  const index = state.findIndex((p) => p.parentFeaturedId === items[0]?.parentFeaturedId) + 1;
  const newQue = Array.from(state);
  newQue.splice(index, 0, ...items);

  return newQue;
};

const reducer = (state: VideoAssetWithParentFeaturedId[], action: Action) => {
  switch (action.type) {
    case ActionTypes.ADD_ITEMS:
      return action.items;
    case ActionTypes.ADD_EMBEDDED:
      return addEmbeddedVideos(state, action.items);
    default:
      console.warn(`Action "${action.type}" is not supported`);
      return state;
  }
};

export function PlayNextContentContextProvider({ children }: { children: ReactNode }) {
  const [pncList, setPncList] = useReducer(reducer, []);
  const [nowPlaying, setNowPlaying] = useState<VideoAssetFieldsFragment | null>(null);
  const isNextContentMutedRef = useRef(false);

  const getNextItem = useCallback(
    (previousId: string) => {
      const currentIndex = pncList.findIndex((item) => item.id === previousId);
      if (currentIndex === -1) {
        return null;
      }
      // Last item
      if (currentIndex === pncList.length - 1) {
        return null;
      }
      return pncList[currentIndex + 1] ?? null;
    },
    [pncList]
  );

  const value: IPlayNextContentContext = useMemo(
    () => ({
      isNextContentMutedRef,
      nowPlaying,
      getNextItem,
      setPncList,
      setNowPlaying,
    }),
    [getNextItem, nowPlaying]
  );

  return <PlayNextContentContext.Provider value={value}>{children}</PlayNextContentContext.Provider>;
}

export const usePlayNextContentContext = () => {
  return useContext(PlayNextContentContext);
};
