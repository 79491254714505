import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

import { TABLET } from 'styles/theme';

export const useIsMobileWidth = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const checkIsMobile = debounce(() => {
      setIsMobile(window.matchMedia(`(min-width: ${TABLET}px)`).matches === false);
    }, 32);

    checkIsMobile();

    addEventListener('resize', checkIsMobile);

    return () => {
      removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
};
