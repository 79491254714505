import type { LinkHierarchyFieldsFragment } from '@news/gql';

import { getLinkHref } from 'components/Link';

export function isPathPartOfLinkHierarchy({
  path,
  linkHierarchy,
}: {
  path: string;
  linkHierarchy: LinkHierarchyFieldsFragment;
}) {
  const childHrefs = linkHierarchy.childLinks.map(getLinkHref);
  const parentHref = linkHierarchy.parentLink ? getLinkHref(linkHierarchy.parentLink) : null;

  return (
    parentHref === path ||
    childHrefs.includes(path) ||
    childHrefs.some((href) => href === '/just-nu' && path.startsWith('/just-nu')) //Needed to ensure that the Secondary tab menu persists when toggling breaking news
  );
}
