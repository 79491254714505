export const trimUrl = (url: string): string => {
  let trimmedUrl = url.trim();

  while (trimmedUrl.endsWith('%20')) {
    trimmedUrl = trimmedUrl.substring(0, trimmedUrl.length - 3).trim();
  }

  while (trimmedUrl.startsWith('%20')) {
    trimmedUrl = trimmedUrl.substring(3).trim();
  }

  return trimmedUrl;
};
