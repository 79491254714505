import React, { useEffect, useRef, useState } from 'react';

import { ServiceMessageRow } from 'components/infoBar/serviceMessages/ServiceMessageRow';
import { isClient } from 'lib/helpers';

const OFFLINE_TIMEOUT = 10 * 1000; // 10 seconds

const NetworkStatusMessage = () => {
  const [isOnline, setIsOnline] = useState(true);
  const timeout = useRef<null | number>(null);

  const onOnline = () => {
    if (timeout?.current) {
      clearTimeout(timeout.current);
    }

    setIsOnline(true);
  };

  const onOffline = () => {
    timeout.current = window.setTimeout(() => {
      setIsOnline(false);
    }, OFFLINE_TIMEOUT);
  };

  useEffect(() => {
    if (!isClient) return;

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  const message = {
    id: 'network-status-message',
    title: 'Offline',
    message: 'Ingen internet anslutning just nu. Kontrollera nätverket.',
  };

  return isOnline ? null : <ServiceMessageRow {...message} />;
};

export { NetworkStatusMessage };
