import React from 'react';

import { PlatformParameter, useServiceMessageQuery } from '@news/gql';

import { ServiceMessageRow } from './ServiceMessageRow';

function ServiceMessageWrapper() {
  const { data } = useServiceMessageQuery({
    variables: {
      platform: PlatformParameter.Web,
    },
  });

  if (data && data.serviceMessages && data?.serviceMessages?.length > 0) {
    return (
      <>
        {data.serviceMessages.map((row) => {
          if (!row) return null;
          return (
            <ServiceMessageRow
              key={`service-message-row-${row.id}`}
              id={row.id}
              title={row.title}
              message={row.message}
            />
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
}

export { ServiceMessageWrapper };
