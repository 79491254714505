import React from 'react';

import { cssSize } from './iconUtils';

export const Tv4Icon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(24)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.39844 15.9152L6.57777 15.3438L5.48365 16.9152H7.39844V15.9152ZM13.8145 6.7002H14.8145V3.51389L12.9939 6.12879L13.8145 6.7002ZM8.21911 16.4866L14.6352 7.2716L12.9939 6.12879L6.57777 15.3438L8.21911 16.4866ZM12.8145 6.7002V19.0752H14.8145V6.7002H12.8145ZM7.39844 16.9152H16.3984V14.9152H7.39844V16.9152Z"
      fill="currentColor"
    />
    <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" fill="none" />
  </svg>
);
