import type { NextComponentType, NextPageContext } from 'next';
import type { AppContext } from 'next/app';
import NextApp from 'next/app';
import React from 'react';

import type { TServerContext } from 'contexts/ServerContext';
import { ServerContext } from 'contexts/ServerContext';

interface InitialProps {
  serverContext: TServerContext & { toJSON: () => void };
}

export function withServerContext(Component: NextComponentType<NextPageContext | AppContext>) {
  function WithServer({ serverContext, ...props }: { serverContext: React.ContextType<typeof ServerContext> }) {
    return React.createElement(ServerContext.Provider, { value: serverContext }, React.createElement(Component, props));
  }

  if (typeof process === 'object' && process.env.NODE_ENV !== 'production')
    WithServer.displayName = `withServer(${Component.displayName || Component.name || 'Component'})`;

  WithServer.getInitialProps = async (context: NextPageContext | AppContext) => {
    const isApp = 'ctx' in context;
    const { req, res } = isApp ? context.ctx : context;
    const props = Component.getInitialProps
      ? await Component.getInitialProps(context)
      : isApp
        ? await NextApp.getInitialProps(context)
        : {};

    if (req)
      (props as InitialProps).serverContext = {
        request: req,
        response: res,

        // This prevents the server attempting to serialize the server context
        // object to JSON for hydration on the client.
        toJSON: () => undefined,
      };

    return props;
  };

  return WithServer;
}
