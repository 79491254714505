import type { PageDetails } from '../pageDetails';
import {
  articlePageDetails,
  breakingNewsPageDetails,
  relatedArticlePageDetails,
  toItemPathFunction,
  videoAssetPageDetails,
} from '../pageDetails';

export const getPageDetailsForType = (typename?: string): PageDetails => {
  switch (typename) {
    case 'Article':
      return articlePageDetails;
    case 'RelatedArticle':
      return relatedArticlePageDetails;
    case 'VideoAsset':
      return videoAssetPageDetails;
    case 'BreakingNews':
      return breakingNewsPageDetails;
    default:
      return {
        page: '/',
        itemPath: toItemPathFunction(''),
      };
  }
};
