import { type GroupFieldsFragment, type LinkFieldsFragment, type LinkHierarchyFieldsFragment } from '@news/gql';

const START_LINK_HIERARCHY_FALLBACK: LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy',
  id: '2n5NVMmq9s4eYUWzSHvrtN',
  icon: 'news',
  categoryTitle: 'Start',
  parentLink: {
    __typename: 'Link',
    id: 'nyheterna',
    linkId: '1AFxAPnyVPLVlF9CTZG1kY',
    title: 'Nyheter',
    href: '/',
    icon: 'news',
    targetType: 'Newsfeed',
  },
  childLinks: [
    {
      __typename: 'Link',
      id: '1o8rcG8Ei6Yt3jXvHeK99u',
      linkId: '3Eat3ViE6B6NXhbm5mrPLs',
      title: 'Senaste nytt',
      href: '/just-nu',
      icon: '',
      targetType: 'InternalLink',
    },
    {
      __typename: 'Link',
      id: '67NtUnXdpGMelATutdByNB',
      linkId: '37SStbpGrXZhRFa4Q85zbv',
      title: 'Inrikes',
      href: '/inrikes',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '4TmudfrdT1etiWzYPGdr6Z',
      linkId: '2TJU3mOrbw0U3VITWW6oog',
      title: 'Utrikes',
      href: '/utrikes',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '3xcDX3hmayBTO5yWW7cs1b',
      linkId: '10cj5DaB8gFB9uTaJ2zSjd',
      title: 'Politik',
      href: '/politik',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '7FcQFmKQd6EqUPyDzedGx2',
      linkId: '4y5QPLSDcZ6XB3CRehwVAC',
      title: 'Ekonomi',
      href: '/ekonomi',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '5TcfYmKcNcrr4jah0glBz8',
      linkId: '2VB9l2W9Om03B1eagTBewn',
      title: 'Brott och straff',
      href: '/brott-och-straff',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: 'LJuimN98XedsMMRKtALkr',
      linkId: '1rq8DJavAQk76Nrv7v63ai',
      title: 'Klimat',
      href: '/klimat',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '6eyisuAk8RWvrHonvyi67s',
      linkId: '77NljCsWAsK190oaf5lD7b',
      title: 'Tech',
      href: '/tech',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: 'vader',
      linkId: '7JKq4tn34Dz0vAax5iYOnQ',
      title: 'Väder',
      href: '/vadret',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '6usPOF3YztacaYZ9L34bac',
      linkId: '7nPlmBcTMTpyOKdaat5JSL',
      title: 'Kriget i Ukraina',
      href: '/kriget-i-ukraina',
      icon: '',
      targetType: 'Newsfeed',
    },
  ],
};

const NOJE_LINK_HIERARCHY_FALLBACK: LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy',
  id: '4FHWNO3yGh1FQ1LzD0FEos',
  icon: 'entertainment',
  categoryTitle: 'Nöje',
  parentLink: {
    __typename: 'Link',
    id: '4CbzYJSOklvm6HbWv0DSzA',
    linkId: '16ECSfvtSCeC2DixxsvrX8',
    title: 'Nöje',
    href: '/noje',
    icon: 'entertainment',
    targetType: 'Newsfeed',
  },
  childLinks: [
    {
      __typename: 'Link',
      id: '3v7kXm9CfuWBJubkm4bZ3V',
      linkId: '5dxbsoMPwGv89kmmPRtbjO',
      title: 'TV',
      href: '/tv',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '4Y4GCUaOIOjZhRYYQC6O9T',
      linkId: '74mkSkxFcTX8PHM1zEZkjS',
      title: 'Film',
      href: '/film',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '4BNnGDangXjjLw0QlGsgHn',
      linkId: '5POrIM8MI0rYcuQaHxGoox',
      title: 'Musik',
      href: '/musik',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '2PF3BqPvrXIL9tOkeP9haE',
      linkId: '2TSNr14Z6NYnZ8Tll0rkDE',
      title: 'Bäst i test',
      href: '/bast-i-test',
      icon: '',
      targetType: 'Newsfeed',
    },
  ],
};

const SPORT_LINK_HIERARCHY_FALLBACK: LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy',
  id: '4dUX23WOXMO5Xduf4gMioR',
  icon: 'sport',
  categoryTitle: 'Sport',
  parentLink: {
    __typename: 'Link',
    id: 'sporten',
    linkId: 'Q9Ii5R2O7DODyWe4sIVXe',
    title: 'Sport',
    href: '/sport',
    icon: 'sport',
    targetType: 'Newsfeed',
  },
  childLinks: [
    {
      __typename: 'Link',
      id: 'wyrvOP3pmOecdBdlBfjz5',
      linkId: '01LMVUxCGf2v8TGZjZNWZx',
      title: 'Fotboll',
      href: '/fotboll',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '6vfl1NYk5A3BPri3acdBwz',
      linkId: '5ojVuJK2lkOVFI1H16GdF',
      title: 'Handboll',
      href: '/handboll',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '2cN4gISodsEgX2CzbLp3l1',
      linkId: '2E6tS8Rb6AF0OxsWub0iHG',
      title: 'Skidor',
      href: '/skidor',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '3VbyVsbEnJ2LJqTRSf6ziR',
      linkId: '2UUtPy13zRjkzeKWBEvbcQ',
      title: 'Ishockey',
      href: '/ishockey',
      icon: '',
      targetType: 'Newsfeed',
    },
  ],
};

const LIVSSTIL_LINK_HIERARCHY_FALLBACK: LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy',
  id: '6W2fL3Hjgutd9vosRifTU3',
  icon: 'lifestyle',
  categoryTitle: 'Livsstil',
  parentLink: null,
  childLinks: [
    {
      __typename: 'Link',
      id: '225VqdWXLGdIbQGL53GbaC',
      linkId: '2RCUbeHVqeeSBcJkeFTi8J',
      title: 'Mode',
      href: '/mode',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '2wuLRyPEJjdX6TqjKbKV3Z',
      linkId: '6YFb6qvt4Z6I1OES7pYOKg',
      title: 'Mat',
      href: '/mat',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '7puW81WFti6rXafNt8JlQp',
      linkId: '5H9aANJKJqsM1R25RADDjb',
      title: 'Hem och hushåll',
      href: '/hem-och-hushall',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '3vf43KRth3Rf1L1X0Q4Mll',
      linkId: '3wJTIJmcx8V42h0VYTL840',
      title: 'Husdjur',
      href: '/husdjur',
      icon: '',
      targetType: 'Newsfeed',
    },
  ],
};

const NYHETSPROGRAM_LINK_HIERARCHY_FALLBACK: LinkHierarchyFieldsFragment = {
  __typename: 'LinkHierarchy',
  id: '5FFBhfb4ZvYSk3IkH7jK7U',
  icon: 'schedule',
  categoryTitle: 'Nyhetsprogram',
  parentLink: null,
  childLinks: [
    {
      __typename: 'Link',
      id: 'nyhetsmorgon',
      linkId: '4lqjhxjlAJGzRQ9XsJA60A',
      title: 'Nyhetsmorgon',
      href: '/nyhetsmorgon',
      icon: 'nyhetsmorgon',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: 'efter-fem',
      linkId: '2uAygt8y8399G0PXStoDny',
      title: 'Efter fem',
      href: '/efter-fem',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: 'kalla-fakta',
      linkId: '7cKxgy2S7jA4J8HogwX7vR',
      title: 'Kalla fakta',
      href: '/kalla-fakta',
      icon: '',
      targetType: 'Newsfeed',
    },
    {
      __typename: 'Link',
      id: '2swGW9yJsgfff0Yazc0EVn',
      linkId: '3nsOZTyy9JH3ZrMu3rj6UV',
      title: 'Se senaste nyhetssändningen på TV4 Play',
      href: 'https://www.tv4play.se/nyheter',
      icon: '',
      targetType: 'ExternalLink',
    },
  ],
};

const NYHETSMORGON_LINK_FALLBACK: LinkFieldsFragment = {
  __typename: 'Link',
  id: 'nyhetsmorgon',
  linkId: '4lqjhxjlAJGzRQ9XsJA60A',
  title: 'Nyhetsmorgon',
  href: '/nyhetsmorgon',
  icon: 'nyhetsmorgon',
  targetType: 'Newsfeed',
};

const TIPSA_TV4_LINK_FALLBACK: LinkFieldsFragment = {
  __typename: 'Link',
  id: 'q4sEKLNPsBtgR8yRdgogm',
  linkId: '5VmRvJLbQdFg2Kq3kVZdIs',
  title: 'Tipsa TV4',
  href: 'https://tv4.23c.se/tips/campaign/tipsatv4/welcome/',
  icon: 'mail',
  targetType: 'ExternalLink',
};

export const PRIMARY_TAB_MENU_DESKTOP_LINKS_FALLBACK: GroupFieldsFragment['links'] = [
  START_LINK_HIERARCHY_FALLBACK,
  NOJE_LINK_HIERARCHY_FALLBACK,
  SPORT_LINK_HIERARCHY_FALLBACK,
  NYHETSMORGON_LINK_FALLBACK,
];

export const PRIMARY_TAB_MENU_MOBILE_LINKS_FALLBACK: GroupFieldsFragment['links'] = [
  START_LINK_HIERARCHY_FALLBACK,
  NOJE_LINK_HIERARCHY_FALLBACK,
  NYHETSMORGON_LINK_FALLBACK,
];

export const BURGER_MENU_LINK_GROUPS_FALLBACK: GroupFieldsFragment[] = [
  {
    __typename: 'Group',
    id: '1enIfAoPV3IdxQlyzYum7L',
    name: 'Main menu - main section (new)',
    links: [
      START_LINK_HIERARCHY_FALLBACK,
      NOJE_LINK_HIERARCHY_FALLBACK,
      SPORT_LINK_HIERARCHY_FALLBACK,
      LIVSSTIL_LINK_HIERARCHY_FALLBACK,
      NYHETSPROGRAM_LINK_HIERARCHY_FALLBACK,
      TIPSA_TV4_LINK_FALLBACK,
    ],
  },
  {
    __typename: 'Group',
    id: '2N6LEEQMFmLJIgt0JfUf3B',
    name: 'Main menu - secondary section (new)',
    links: [
      {
        __typename: 'Link',
        id: '3oG12a5M561jsXln8pqRAY',
        linkId: '6JQJ7rALr2HzZcgpcwI9tl',
        title: 'Program A-Ö',
        href: '/program',
        icon: '',
        targetType: 'InternalLink',
      },
      {
        __typename: 'Link',
        id: '2Gy0RSdLZbh8YTwCxHeMFT',
        linkId: 'dhjfdTkUzi9J3MfZuh7IP',
        title: 'Tablå',
        href: '/tabla',
        icon: '',
        targetType: 'InternalLink',
      },
      {
        __typename: 'Link',
        id: 'HOiP6GG9h2Q9fFrEMFvhO',
        linkId: '5eTERTXS6b9mYg4DH2FPA8',
        title: 'Medverka',
        href: '/medverka',
        icon: '',
        targetType: 'Newsfeed',
      },
    ],
  },
  {
    __typename: 'Group',
    id: '24LiOlrMJMj5gjfcWthBK7',
    name: 'Main menu - last section',
    links: [
      {
        __typename: 'Link',
        id: '5b07c2b424bc62a7002b524d',
        linkId: '6e4azEUpVEhb7hCqjdFhd6',
        title: 'Cookie-policy',
        href: 'cookiepolicy-www-tv4-se',
        icon: '',
        targetType: 'Article',
      },
      {
        __typename: 'Link',
        id: '2RfbhPXnyHDBhsmIlM6eCo',
        linkId: 'LtAotaCDdI3Xb0xN6856q',
        title: 'Annonsera',
        href: 'annonsera-www-tv4-se',
        icon: '',
        targetType: 'Article',
      },
      {
        __typename: 'Link',
        id: '5742a60ffca38f120d00046b',
        linkId: 'd5vM05g9TdzutTkRBCizf',
        title: 'Användarvillkor',
        href: 'allmanna-anvandarvillkor-for-tv4-s-tjanster',
        icon: '',
        targetType: 'Article',
      },
      {
        __typename: 'Link',
        id: '5hf1DpiobXuItAdvJ37B3W',
        linkId: 'd13xN6KSVtLNRpbqSf0Ss',
        title: 'Klander från MO och MEN',
        href: '/klander-fran-mo',
        icon: '',
        targetType: 'Newsfeed',
      },
      {
        __typename: 'Link',
        id: '6N38hoLmhlEi6qbuMwgDbN',
        linkId: '1Gki0g3McyzsMbE7ryFKQ9',
        title: 'Press',
        href: 'http://press.tv4.se',
        icon: '',
        targetType: 'ExternalLink',
      },
      {
        __typename: 'Link',
        id: 'UPHkLQEs1UqnrVgLhjQ17',
        linkId: '2z6CoulIdbAMtA7xEpGJIU',
        title: 'Jobba hos oss',
        href: 'https://jobb.tv4.se/',
        icon: '',
        targetType: 'ExternalLink',
      },
      {
        __typename: 'Link',
        id: '4Qhj6KAd8hwaL4HZwg2uBL',
        linkId: '07ulm5H3tFjQYtosAzwpc',
        title: 'Publicistisk grund',
        href: 'hur-fungerar-den-publicistiska-grunden-foer-nyhets-och-samhaellsjournalistik',
        icon: '',
        targetType: 'Article',
      },
      {
        __typename: 'Link',
        id: '4FASkwNxMHxSiSi8XBG6mf',
        linkId: '45Sv2oWk9xs4Vi8uf1vMPq',
        title: 'Ansvariga utgivare',
        href: 'ansvariga-utgivare-foer-innehall-pa-tv4-play-och-tv4-se',
        icon: '',
        targetType: 'Article',
      },
      {
        __typename: 'Link',
        id: '21S40PKdrQZDCEJllEPwRj',
        linkId: '54KqaglJaJSqaSMguG38Qo',
        title: 'Kontakta TV4',
        href: 'hur-kontaktar-jag-tv4',
        icon: '',
        targetType: 'Article',
      },
    ],
  },
];
