interface Props {
  size?: number;
  className?: string;
}

export const ArrowIcon = ({ size = 14, className }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 408 408"
    fill="currentColor"
    className={className}
  >
    <g>
      <polygon points="204,0 168.3,35.7 311.1,178.5 0,178.5 0,229.5 311.1,229.5 168.3,372.3 204,408 408,204 		" />
    </g>
  </svg>
);
