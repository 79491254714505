import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const FacebookIcon = ({ size = 20, color = '#3B5998' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 8 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facebook icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.125 5.75H0.25V8.375H2.125V16.25H5.25V8.375H7.52625L7.75 5.75H5.25V4.65603C5.25 4.02931 5.37 3.52859 5.94688 3.52859H7.75V0.5H5.37C3.1225 0.5 2.125 1.53884 2.125 3.52859V5.75Z"
      fill={color}
    />
  </svg>
);
