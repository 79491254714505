import { type ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { BurgerMenu } from 'components/burger-menu';
import { PrimaryButton } from 'components/buttons';
import { BurgerIcon } from 'components/icons';
import { useBurgerMenu } from 'contexts/BurgerMenuContext';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { useIsMobileWidth } from 'hooks/useIsMobileWidth';
import { ScrollDirection, useScrollDirection } from 'hooks/useScrollDirection';
import { isFeedItemPage } from 'lib/helpers/isFeedItemPage';
import { menuBlur } from 'styles/mixins/blur';
import { menuTransition } from 'styles/mixins/menuTransition';
import { MAX_CONTENT_WIDTH, theme } from 'styles/theme';

import { Logo } from './Logo';
import { PrimaryTabMenuMobile } from './PrimaryMenuMobile';
import { PrimaryTabMenuDesktop } from './PrimaryTabMenuDesktop';
import { SecondaryTabMenu } from './SecondaryTabMenu';
import { TV4PlayButton } from './TV4PlayButton';

interface MenuProps {
  thirdRowMenu?: ReactNode;
}

const Menu = ({ thirdRowMenu }: MenuProps) => {
  const currentRoute = useCurrentRoute();
  const scrollDirection = useScrollDirection();
  const isMobile = useIsMobileWidth();

  const { toggle } = useBurgerMenu();

  const isTopMenuStatic = isFeedItemPage(currentRoute.path);
  const isMenusHidden = isMobile && scrollDirection === ScrollDirection.Down;

  const isTopMenuHidden = isMenusHidden && !isTopMenuStatic;

  return (
    <>
      <MenuTopSection $isHidden={isTopMenuHidden} data-is-hidden={isTopMenuHidden}>
        <Topbar>
          <Logo />
          <PrimaryTabMenuDesktop />
          <TopBarEndSection>
            <TV4PlayButton />
            <BurgerButton aria-label="Öppna hamburgarmeny" onClick={toggle}>
              <StyledBurgerIcon />
            </BurgerButton>
          </TopBarEndSection>
        </Topbar>
        <Divider />
        <SecondaryTabMenu />
        {thirdRowMenu}
      </MenuTopSection>
      <PrimaryTabMenuMobile isHidden={isMenusHidden} />

      <BurgerMenu />
    </>
  );
};

export { Menu };

const BurgerButton = styled(PrimaryButton)`
  display: none;

  ${theme.mq.tablet} {
    display: block;
    height: ${sizeUnits[40]};
  }
`;

const StyledBurgerIcon = styled(BurgerIcon)`
  display: block;
  margin: auto;
`;

// TranslateY needs to go from 0 to -100% to avoid issues on Safari.
// If we dynamically add and remove the transform property it becomes inconsistent on Safari sometimes.
export const MenuTopSection = styled.div<{ $isHidden: boolean }>`
  ${menuBlur}
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.menu};
  transform: translateY(${({ $isHidden }) => ($isHidden ? '-100%' : '0%')});
  ${menuTransition}
`;

const Topbar = styled.div`
  display: flex;
  position: relative;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: 0 auto;
  align-items: center;
  padding: ${sizeUnits[8]} ${sizeUnits[16]};
  box-shadow: 0 ${sizeUnits[4]} ${sizeUnits[4]} 0 ${colors.black[5]};
  z-index: ${theme.zIndex.menu};

  ${theme.mq.tablet} {
    height: 65px;
    display: flex;
    padding: 0 ${sizeUnits[40]};
    box-shadow: none;
  }
`;

const TopBarEndSection = styled.div`
  display: flex;
  margin-left: auto;
  gap: ${sizeUnits[16]};
`;

const Divider = styled.div`
  display: none;

  ${theme.mq.tablet} {
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${colors.gray.light};
  }
`;
