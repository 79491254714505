import { createContext, useContext, useEffect, useState } from 'react';

import { isClient } from 'lib/helpers';

/** @public */
export enum ConsentGroup {
  Necessary = 'C0001',
  /** Performance Cookies are used to trigger performance metrics of content. E.g. for Kilkaya */
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004',
}

declare global {
  interface WindowEventMap {
    'consent.onetrust': CustomEvent<ConsentGroup[]>;
  }
  interface Window {
    OnetrustActiveGroups?: string;
  }
}

interface ConsentContextData {
  activeGroups: ConsentGroup[];
}

const ConsentContext = createContext<ConsentContextData>({
  activeGroups: [],
});

export const ConsentContextProvider = ({ children }: React.PropsWithChildren) => {
  const [activeGroups, setActiveGroups] = useState<ConsentGroup[]>([]);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    const currentGroups =
      (window.OnetrustActiveGroups?.split(',').filter((group) => group !== '') as ConsentGroup[]) ?? [];

    setActiveGroups(currentGroups);

    const onConsentChanged = (event: CustomEvent<ConsentGroup[]>) => {
      setActiveGroups(event.detail);
    };
    window.addEventListener('consent.onetrust', onConsentChanged);

    return () => {
      window.removeEventListener('consent.onetrust', onConsentChanged);
    };
  }, []);

  return <ConsentContext.Provider value={{ activeGroups }}>{children}</ConsentContext.Provider>;
};

export const useConsentContext = () => useContext(ConsentContext);
