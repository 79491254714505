import { BroadcastStatus, HumanDate } from './types';

interface IProps {
  day: string | undefined;
  index: number;
  liveIndex: number;
}

export const setProgramStatus = ({ day, index, liveIndex }: IProps): string => {
  if (!day || day === HumanDate.YESTERDAY) return BroadcastStatus.PAST_TENSE;
  if (day === HumanDate.TOMORROW) return BroadcastStatus.UPCOMING;
  if (index < liveIndex) {
    return BroadcastStatus.PAST_TENSE;
  } else if (index === liveIndex) {
    return BroadcastStatus.LIVE;
  } else {
    return BroadcastStatus.UPCOMING;
  }
};
