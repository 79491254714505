import { dateString, getHour, getWeekday } from '../helpers/timeFunctions';
import type { ScheduleDay } from './types';
import { HumanDate } from './types';

const MAX_DAYS_IN_FUTURE = 6; // Schedule API don't have data further in future

const oneDayInMilliSeconds = 24 * 60 * 60 * 1000;

const getDays = (now: number, num = 1): number[] => {
  const arr: number[] = [];
  for (let i = 0; i <= num; i++) {
    arr.push(now + oneDayInMilliSeconds * i);
  }
  return arr;
};

// The schedule day for our channels are from 05:00 to 05:00 the next day.
// So if current time is 2021-11-02T02:00, we fetch yesterdays (2021-11-01) schedule to be able to show progress.
const SCHEDULE_START_TIME = 5;

export const getScheduleDates = (now: number): ScheduleDay[] => {
  const today = new Date(now);
  const yesterday = now - oneDayInMilliSeconds;
  const currentHours = getHour(today);
  const days = getDays(currentHours >= SCHEDULE_START_TIME ? now : yesterday, MAX_DAYS_IN_FUTURE);
  const allDays = ([] as number[]).concat(currentHours >= 5 ? yesterday : yesterday - oneDayInMilliSeconds, days);

  return allDays.map((d, index) => {
    let text = '';
    const date = new Date(d);
    const humanTextDate = new Date(d + oneDayInMilliSeconds);
    const dateToWrite =
      currentHours >= SCHEDULE_START_TIME
        ? dateString(date, { month: 'numeric', day: 'numeric' })
        : dateString(humanTextDate, { month: 'numeric', day: 'numeric' });
    switch (index) {
      case 0:
        text = `${HumanDate.YESTERDAY} ${dateToWrite}`;
        break;
      case 1:
        text = `${HumanDate.TODAY} ${dateToWrite}`;
        break;
      case 2:
        text = `${HumanDate.TOMORROW} ${dateToWrite}`;
        break;
      default: {
        text = `${getWeekday(date)} ${dateToWrite}`;
      }
    }
    return {
      // TODO(axel): Fix for Android, which also includes the time in the results
      date: dateString(date, { dateStyle: 'short' }).split(' ')[0] ?? '',
      text,
    };
  });
};
