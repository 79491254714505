export const colors = {
  black: {
    0: 'rgba(0, 0, 0, 0)',
    5: 'rgba(0, 0, 0, 0.05)',
    10: 'rgba(0, 0, 0, 0.1)',
    15: 'rgba(0, 0, 0, 0.15)',
    20: 'rgba(0, 0, 0, 0.2)',
    25: 'rgba(0, 0, 0, 0.25)',
    30: 'rgba(0, 0, 0, 0.3)',
    40: 'rgba(0, 0, 0, 0.4)',
    50: 'rgba(0, 0, 0, 0.5)',
    60: 'rgba(0, 0, 0, 0.6)',
    70: 'rgba(0, 0, 0, 0.7)',
    80: 'rgba(0, 0, 0, 0.8)',
    90: 'rgba(0, 0, 0, 0.9)',
    100: 'rgb(0, 0, 0)',
  },
  white: {
    0: 'rgba(255, 255, 255, 0)',
    5: 'rgba(255, 255, 255, 0.05)',
    10: 'rgba(255, 255, 255, 0.1)',
    15: 'rgba(255, 255, 255, 0.15)',
    20: 'rgba(255, 255, 255, 0.2)',
    25: 'rgba(255, 255, 255, 0.25)',
    30: 'rgba(255, 255, 255, 0.3)',
    40: 'rgba(255, 255, 255, 0.4)',
    50: 'rgba(255, 255, 255, 0.5)',
    60: 'rgba(255, 255, 255, 0.6)',
    70: 'rgba(255, 255, 255, 0.7)',
    80: 'rgba(255, 255, 255, 0.8)',
    90: 'rgba(255, 255, 255, 0.9)',
    95: 'rgba(255, 255, 255, 0.95)',
    100: 'rgb(255, 255, 255)',
  },
  gray: {
    fade: '#F2F2F2',
    light: '#E9E9E9',
    dark: '#DCDCDC',
    darker: '#808080',
  },
  yellow: {
    soft: '#FFF9E7',
    bright: '#FEDB07',
  },
  red: {
    tv4: '#FF3334',
    tv4Light: '#E0001C',
  },
  transparent: 'transparent',
} as const;
