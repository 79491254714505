import styled, { type CSSProperties, css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { LinkComponent } from 'components/Link';
import { Typography } from 'components/Typography';

const burgerMenuItem = css`
  display: block;
  color: ${colors.black[70]};
  padding-top: ${sizeUnits[4]};
  padding-bottom: ${sizeUnits[4]};
  margin-top: ${sizeUnits[4]};
  margin-bottom: ${sizeUnits[4]};
`;

export const BurgerMenuLink = styled(LinkComponent)`
  ${burgerMenuItem}
  position: relative;

  &[aria-current='page'] {
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -${sizeUnits[16]};
      width: ${sizeUnits[16]};
      border-left: ${sizeUnits[4]} solid ${colors.red.tv4};
    }
  }
`;

export const BurgerMenuButton = styled.button`
  ${burgerMenuItem}
  width: 100%;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

export const BurgerMenuLinkText = styled(Typography)<{ $color?: CSSProperties['color'] }>`
  display: flex;
  gap: ${sizeUnits[8]};
  align-items: center;
  color: ${({ $color }) => $color};
`;

export const LinkGroupItemWrapper = styled.li<{ $verticalPadding: CSSProperties['paddingTop'] }>`
  &:not(:last-child) {
    padding-bottom: ${({ $verticalPadding }) => $verticalPadding};
  }

  &:not(:first-child) {
    padding-top: ${({ $verticalPadding }) => $verticalPadding};
  }
`;
