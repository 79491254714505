import type { NewsFeedItem } from '@news/lib';

import { getPageDetailsForType } from './getPageDetailsForType';
import { nidToSlug } from './nidToSlug';

export const getRedirectPathForItem = (item: NewsFeedItem, path: string): string => {
  if ('slug' in item && item.slug !== '' && item.slug !== null) {
    // NOTE: The search API can deliver items with no slug. Ex.: https://tv4-search.a2d.tv/assets?id=3898633
    // The rest is just a safety net for future updates in Contentful and the Preview app.
    const queryParams = path.split('?')[1] ?? '';
    const separator = queryParams === '' ? '' : '?';
    const newsEntryPath = getPageDetailsForType(item.__typename).itemPath;

    return `${newsEntryPath(item.id, item.slug)}${separator}${queryParams}`;
  } else {
    return item.__typename === 'VideoAsset' ? nidToSlug(item.program?.nid || '') : '/';
  }
};
