import { colors } from '@news/design-tokens/src/colors';
import { TV4_FIFTH_ELEMENT_URL } from '@news/lib';

import { ImageProxy } from 'lib/imageProxy';

export const fifthElementBackground = ({ backgroundWidth }: { backgroundWidth: number }) => `
  color: ${colors.white[100]};
  background-image: url(${ImageProxy.url({ url: TV4_FIFTH_ELEMENT_URL, width: backgroundWidth })}), none;
  background-color: ${colors.red.tv4};
  background-position: 50% 77%;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    text-decoration: none;
  }
`;
