import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const CloseIcon = ({ color = 'currentColor', size = 24 }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34313 6.34315C6.73366 5.95262 7.36682 5.95262 7.75735 6.34315L12 10.5858L16.2426 6.34315C16.6332 5.95262 17.2663 5.95262 17.6568 6.34315C18.0474 6.73367 18.0474 7.36684 17.6568 7.75736L13.4142 12L17.6568 16.2426C18.0474 16.6332 18.0474 17.2663 17.6568 17.6569C17.2663 18.0474 16.6332 18.0474 16.2426 17.6569L12 13.4142L7.75735 17.6569C7.36682 18.0474 6.73366 18.0474 6.34313 17.6569C5.95261 17.2663 5.95261 16.6332 6.34313 16.2426L10.5858 12L6.34313 7.75736C5.95261 7.36684 5.95261 6.73367 6.34313 6.34315Z"
      fill={color}
    />
  </svg>
);
