import type { RelatedArticleFieldsFragment } from '@news/gql';

import type { BreakingNewsEntry, NewsEntry, NewsFeedItem, Unpacked } from '../types';

export const isTypeNewsEntry = (
  item: NewsFeedItem | RelatedArticleFieldsFragment | BreakingNewsEntry
): item is Extract<typeof item, { __typename: NewsEntry['__typename'] }> => {
  const types: NewsEntry['__typename'][] = ['Article', 'VideoAsset'];
  return types.includes(item?.__typename as Unpacked<typeof types>);
};
