export const BigArrow = ({ className, size = 24 }: { className?: string; size?: number }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.49473 4.91637C7.94245 4.91637 7.49473 5.36408 7.49473 5.91637C7.49473 6.46865 7.94245 6.91637 8.49473 6.91637L15.2729 6.91637L5.66631 16.523C5.27578 16.9135 5.27578 17.5467 5.66631 17.9372C6.05683 18.3277 6.69 18.3277 7.08052 17.9372L16.6871 8.33058V15.1088C16.6871 15.661 17.1348 16.1088 17.6871 16.1088C18.2394 16.1088 18.6871 15.661 18.6871 15.1088L18.6871 5.41637C18.6871 5.14022 18.4633 4.91637 18.1871 4.91637L8.49473 4.91637Z"
      fill="currentColor"
    />
  </svg>
);
