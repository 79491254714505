import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import type { KilkayaPageEventPayload } from 'tracking/page';
import { getKilkayaReferrer, pushKilkayaPageEvent, pushPageEvent } from 'tracking/page';

import type { KilkayaPageView, PageData } from '@news/tracking';

import { ConsentGroup, useConsentContext } from './ConsentContext';

/** @internal */
export interface PageInfo {
  pageData: PageData;
  // Data sent to Kilkaya, if this is not defined, we should not send data to Kilkaya for this page
  kilkayaData?: Omit<
    KilkayaPageView,
    'channel' | 'appId' | 'referrer' | 'pagePath' | 'pageType' | 'pageTitle' | 'pageSection'
  >;
}

type PartialData = {
  pageData: Partial<PageInfo['pageData']>;
  kilkayaData?: Partial<PageInfo['kilkayaData']>;
};

/** @internal */
export type PageContextData = Partial<PageInfo> & {
  updatePageInfo: (newInfo: PageInfo) => void;
  /** Trigger an additional page event from a child component */
  sendPageEvent: (overrides?: PartialData) => void;
};

const PageContext = createContext<PageContextData>({
  updatePageInfo: () => {},
  sendPageEvent: () => {},
});

export const PageContextProvider = ({ children }: React.PropsWithChildren) => {
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(undefined);
  const { activeGroups } = useConsentContext();
  const [kilkayaEventQueue, setKilkayaEventQueue] = useState<KilkayaPageEventPayload[]>([]);

  const sendPageEvent = useCallback(
    (overrides?: PartialData) => {
      if (!pageInfo) {
        return;
      }

      const mergedPageData = {
        ...pageInfo.pageData,
        ...overrides?.pageData,
      };
      pushPageEvent(mergedPageData);

      const mergedKilkayaData = {
        ...pageInfo.kilkayaData,
        ...overrides?.kilkayaData,
      };

      setKilkayaEventQueue((prevState) => [
        ...prevState,
        {
          pagePath: mergedPageData.page_path,
          pageTitle: mergedPageData.page_title,
          pageType: mergedPageData.page_type,
          pageSection: mergedPageData.page_feed ?? undefined,
          referrer: getKilkayaReferrer(),
          ...mergedKilkayaData,
        },
      ]);
    },
    [pageInfo]
  );

  const updatePageInfo = useCallback((pageInfo: PageInfo) => {
    setPageInfo(pageInfo);
  }, []);

  useEffect(() => {
    // Trigger page event each time any of the dependencies change
    sendPageEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo]);

  useEffect(() => {
    // We should only track Kilkaya events if the user has accepted performance cookies.
    if (activeGroups.includes(ConsentGroup.Performance) && kilkayaEventQueue.length > 0) {
      kilkayaEventQueue.forEach((event) => {
        pushKilkayaPageEvent(event);
      });
      setKilkayaEventQueue([]);
    }
  }, [kilkayaEventQueue, activeGroups]);

  return <PageContext.Provider value={{ ...pageInfo, updatePageInfo, sendPageEvent }}>{children}</PageContext.Provider>;
};

export const usePageContext = () => useContext(PageContext);
