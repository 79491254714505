import { useEffect, useState } from 'react';

import { isClient } from 'lib/helpers';
import { isBrowserSupported } from 'lib/isBrowserSupported';

const useIsBrowserSupported = (): boolean => {
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    const supportCheck = isBrowserSupported(window.navigator);
    setIsSupported(supportCheck);
  }, []);

  return isSupported;
};

export { useIsBrowserSupported };
