import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const StopIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg style={cssSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 5C4 4.44772 4.58547 4 5.30769 4H18.6923C19.4145 4 20 4.44772 20 5V19C20 19.5523 19.4145 20 18.6923 20H5.30769C4.58547 20 4 19.5523 4 19V5Z"
      fill={color}
    />
  </svg>
);
