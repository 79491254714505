import { useEffect, useState } from 'react';

import { Typography } from 'components/Typography';
import { useIsBrowserSupported } from 'hooks/useIsBrowserSupported';
import { cookieService as cookies } from 'lib/cookieService';
import { isClient } from 'lib/helpers';

import { CloseIcon, InfoIcon } from '../icons';
import { CloseButton, I, MessageBar, Unsupported } from './styles';

function UnsupportedBrowser() {
  const isSupported = useIsBrowserSupported();

  const [showBar, setShowBar] = useState(false);

  const handleClose = () => {
    cookies.set({
      name: 'unsupportedBrowser',
      value: 'n',
      options: { expires: 1 },
    });
    setShowBar(false);
  };

  useEffect(() => {
    if (!isClient) {
      return;
    }

    if (!cookies.get('unsupportedBrowser')) {
      setShowBar(true);
    }
  }, []);

  if (!showBar || isSupported) {
    return null;
  }

  return (
    <MessageBar data-testid="info-bar">
      <Unsupported>
        <I aria-hidden="true">
          <InfoIcon />
        </I>
        <Typography variant="body2-strong">
          <a target="_blank" rel="noopener noreferrer" href="http://browsehappy.com/?locale=sv_SE">
            Uppdatera din browser för att TV4.se ska fungera optimalt.
          </a>
        </Typography>
        <CloseButton aria-label="Stäng meddelande" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </Unsupported>
    </MessageBar>
  );
}

export { UnsupportedBrowser };
