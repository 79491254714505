import type { RelatedItem } from '../types';

export const getRelatedTitle = (related: RelatedItem): string | undefined => {
  switch (related.__typename) {
    case 'RelatedArticle':
      return related.title;
    case 'VideoPlayerData':
      return related.caption || related.asset.title;
  }
};

export const getRelatedHumanPublishedAt = (related: RelatedItem): string | undefined => {
  switch (related.__typename) {
    case 'RelatedArticle':
      return related.humanPublishedDateTime || undefined;
    case 'VideoPlayerData':
      return related.asset.humanPublishedDateTime || undefined;
  }
};
