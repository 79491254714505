import React from 'react';

import type { SizeAndColor } from './iconUtils';
import { cssSize } from './iconUtils';

export const ShareIcon = ({ size = 24, color = 'currentColor' }: SizeAndColor) => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(size)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7071 5.70711L12 1L7.29289 5.70711C6.90237 6.09763 6.90237 6.7308 7.29289 7.12132C7.68342 7.51184 8.31658 7.51184 8.70711 7.12132L11 4.82843L11 15.4142C11 15.9665 11.4477 16.4142 12 16.4142C12.5523 16.4142 13 15.9665 13 15.4142L13 4.82843L15.2929 7.12132C15.6834 7.51184 16.3166 7.51184 16.7071 7.12132C17.0976 6.7308 17.0976 6.09763 16.7071 5.70711ZM7 11.4142C6.44772 11.4142 6 11.8619 6 12.4142V19.4142C6 19.9665 6.44772 20.4142 7 20.4142H17C17.5523 20.4142 18 19.9665 18 19.4142V12.4142C18 11.8619 17.5523 11.4142 17 11.4142H16C15.4477 11.4142 15 10.9665 15 10.4142C15 9.86193 15.4477 9.41422 16 9.41422H17C18.6569 9.41422 20 10.7574 20 12.4142V19.4142C20 21.0711 18.6569 22.4142 17 22.4142H7C5.34315 22.4142 4 21.0711 4 19.4142V12.4142C4 10.7574 5.34315 9.41422 7 9.41422H8C8.55228 9.41422 9 9.86193 9 10.4142C9 10.9665 8.55228 11.4142 8 11.4142H7Z"
      fill={color}
    />
  </svg>
);
