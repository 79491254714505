interface ProgressCounter {
  now: number;
  start: string;
  end: string;
}

export const progressCounter = ({ now, start, end }: ProgressCounter): number => {
  const s = new Date(start).getTime();
  const totalTime = new Date(end).getTime() - s;
  const progress = now - s;
  const percentage = Math.floor((progress / totalTime) * 100);
  return percentage;
};
