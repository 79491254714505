import { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { type LinkFieldsFragment, type LinkHierarchyFieldsFragment, useMenuQuery } from '@news/gql';
import { PRIMARY_TAB_MENU_MOBILE_ID } from '@news/lib';
import { GroupId } from '@news/tracking';

import { LinkComponent, getLinkHref } from 'components/Link';
import { Typography } from 'components/Typography';
import { IconChooser, type MenuIcon } from 'components/burger-menu/IconChooser';
import { ExploreIcon } from 'components/icons';
import { useBurgerMenu } from 'contexts/BurgerMenuContext';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import { isClient } from 'lib/helpers';
import { isPathPartOfLinkHierarchy } from 'lib/helpers/isPathPartOfLinkHierarchy';
import { menuBlur } from 'styles/mixins/blur';
import { menuTransition } from 'styles/mixins/menuTransition';
import { PRIMARY_TAB_MENU_HEIGHT_MOBILE, theme } from 'styles/theme';

import { PRIMARY_TAB_MENU_MOBILE_LINKS_FALLBACK } from './constants';

interface Props {
  isHidden: boolean;
}

export const PrimaryTabMenuMobile = ({ isHidden }: Props) => {
  const { toggle } = useBurgerMenu();
  const currentRoute = useCurrentRoute();

  const { data, loading, error } = useMenuQuery({
    variables: {
      id: PRIMARY_TAB_MENU_MOBILE_ID,
    },
  });

  const isErrorOnServer = error && !isClient;

  // If an error occurs on the server, it should return the loading-state
  // as the query is going to be rerun och the client. Otherwise there will be a hydration error.
  if (loading || isErrorOnServer) return null;

  const primaryTabMenuLinks = error ? PRIMARY_TAB_MENU_MOBILE_LINKS_FALLBACK : data?.menu?.groups[0]?.links;

  if (!primaryTabMenuLinks) return null;

  return (
    <PrimaryTabMenuMobileWrapper $isHidden={isHidden} aria-label="Primär menu">
      <PrimaryMenuMobileTabsWrapper>
        {primaryTabMenuLinks.map((linkGroupItem, index) => (
          <LinkGroupItem
            key={linkGroupItem.id}
            linkGroupItem={linkGroupItem}
            currentPath={currentRoute.feed}
            index={index}
          />
        ))}
        <li>
          <BurgerButton onClick={toggle}>
            <StyledExploreIcon />
            <Typography variant="small-button-text">Utforska</Typography>
          </BurgerButton>
        </li>
      </PrimaryMenuMobileTabsWrapper>
    </PrimaryTabMenuMobileWrapper>
  );
};

interface LinkGroupItemProps {
  linkGroupItem: LinkFieldsFragment | LinkHierarchyFieldsFragment;
  currentPath: string;
  index: number;
}

const LinkGroupItem = ({ linkGroupItem, currentPath, index }: LinkGroupItemProps) => {
  const isCurrent: boolean = useMemo(() => {
    if (linkGroupItem.__typename === 'Link') {
      return getLinkHref(linkGroupItem) === currentPath;
    }
    return isPathPartOfLinkHierarchy({ path: currentPath, linkHierarchy: linkGroupItem });
  }, [currentPath, linkGroupItem]);

  let link: LinkFieldsFragment | null;
  let title: string | null;

  switch (linkGroupItem.__typename) {
    case 'Link':
      link = linkGroupItem;
      title = linkGroupItem.title;
      break;
    case 'LinkHierarchy':
      link = linkGroupItem.parentLink;
      title = linkGroupItem.categoryTitle;
      break;
  }

  if (!link) return null; //This might happen if the editor adds a LinkHierarchy without a parentLink to the primary-tab-menu-desktop entity

  return (
    <li>
      <PrimaryTabMenuLink current={isCurrent} link={link} groupId={GroupId.PrimaryTabMenuMobile} index={index}>
        <>
          <CategoryIcon $active={isCurrent} icon={linkGroupItem.icon as MenuIcon | null} />
          <CategoryText variant="small-button-text">{title}</CategoryText>
        </>
      </PrimaryTabMenuLink>
    </li>
  );
};

const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[4]};
  color: ${colors.black[70]};
  align-items: center;
  width: 100%;
`;

const CategoryIcon = styled(IconChooser)<{ $active: boolean }>`
  color: ${({ $active }) => ($active ? colors.red.tv4 : colors.black[70])};
`;

const CategoryText = styled(Typography)`
  text-align: center;
`;

const StyledExploreIcon = styled(ExploreIcon)`
  color: ${colors.black[70]};
`;

const PrimaryTabMenuLink = styled(LinkComponent)`
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[4]};
  color: ${colors.black[70]};
  align-items: center;

  &[aria-current='page'] {
    color: ${colors.red.tv4};
  }
`;

const PrimaryMenuMobileTabsWrapper = styled.ul`
  margin: 0 auto;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: ${sizeUnits[8]};
  padding-left: 0;
`;

// TranslateY needs to go from 0 to 100% to avoid issues on Safari.
// If we dynamically add and remove the transform property it becomes inconsistent on Safari sometimes.
const PrimaryTabMenuMobileWrapper = styled.nav<{ $isHidden: boolean }>`
  ${menuBlur}
  position: fixed;
  height: ${PRIMARY_TAB_MENU_HEIGHT_MOBILE}px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: ${theme.zIndex.menu};
  bottom: 0;
  transform: translateY(${({ $isHidden }) => ($isHidden ? '100%' : '0%')});
  ${menuTransition}
  border-top: 1px solid ${colors.black[10]};

  ${theme.mq.tablet} {
    display: none;
  }
`;
