import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

type TBurgerMenuContext = {
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
};

const BurgerMenuContext = React.createContext<TBurgerMenuContext>({
  isOpen: false,
  close: () => {},
  toggle: () => {},
});

export const BurgerMenuContextProvider = ({ children }: React.PropsWithChildren) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (router && typeof window !== 'undefined') {
      router.events.on('routeChangeStart', close);
    }
    return () => {
      router.events.off('routeChangeStart', close);
    };
  }, [close, router]);

  return (
    <BurgerMenuContext.Provider
      value={{
        isOpen,
        close,
        toggle,
      }}
    >
      {children}
    </BurgerMenuContext.Provider>
  );
};

export const useBurgerMenu = () => React.useContext(BurgerMenuContext);
