export const slugify = (str: string): string => {
  return (
    str
      // Remove accents, umlauts etc
      .normalize('NFD')
      // Get rid of diacritics
      .replace(/[\u0300-\u036f]/g, '')
      // Remove leading and trailing whitespace
      .trim()
      .toLowerCase()
      // 0-9 and a-z is allowed in url
      .replace(/[^a-z0-9]/g, '-')
      // replace multiple occurences of "-"
      .replace(/-+/g, '-')
      // remove leading and trailing "-"
      .replace(/^-|-$/g, '')
  );
};
