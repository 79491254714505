import React from 'react';

type ChevronProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  direction?: 'up' | 'down' | 'left' | 'right';
  className?: string;
};

const dirToRotation = {
  up: 270,
  down: 90,
  left: 180,
  right: 0,
};

export const Chevron = ({ color = '#3C3C3C', size = 24, direction = 'right', style, className }: ChevronProps) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    style={{
      transform: `rotate(${dirToRotation[direction]}deg)`,
      ...style,
    }}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path d="M9 18L15 12L9 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
