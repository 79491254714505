export const colors = {
  font: {
    headline: '#000000', // grey.0
    body: '#000', // black.full
    dim: '#4D4D4D', // grey.3
  },
  black: {
    5: '#f2f2f2',
    10: '#e6e6e6',
    50: '#808080',
    dark: '#000e1a',
    medium: '#3c3c3c',
    full: '#000',
    transparent: 'rgba(0, 0, 0, .6)',
  },
  blue: {
    info: '#1B7BD7',
  },
  red: {
    0: '#4D082E',
    1: '#700E39', // Pressed state on white button-text
    2: '#94153E',
    3: '#B81D3F',
    4: '#DB283B', // Active/Focused states
    5: '#FF3334', // main accent color
    6: '#FF545A',
    7: '#FF7680', // Light theme hover state
    8: '#FF9AA5',
    9: '#FFC0CA', // Light theme pressed state
    10: '#FFE8EC',
    tv4: '#ff3334',
    darkTv4: '#da2c2d',
    lightTv4: '#fdafaf',
    dark: '#7a070f',
  },
  yellow: {
    light: '#FFF8E7',
  },
  grey: {
    0: '#000000',
    1: '#1A1A1A',
    2: '#333333',
    3: '#4D4D4D',
    4: '#666666',
    5: '#808080',
    6: '#999999',
    7: '#B3B3B3',
    8: '#CCCCCC',
    9: '#e6e6e6',
    10: '#ffffff',
    background: '#e1e1e1',
    default: '#616161',
    dark: '#3c3c3c',
    darkFigma: '#4d4d4d',
    black: '#1b1b1b',
    light: '#ebebeb',
    medium: '#a3a3a3',
    mediumFigma: '#C6C6C6',
    subtle: '#fcfcfc',
    discreteText: 'rgba(0, 0, 0, 0.6)',
  },
  white: {
    offWhite: '#f5f5f5',
    full: '#fff',
  },
  beige: {
    light: '#F9F3EE',
    dark: '#F5EDE3',
  },
  pink: {
    default: '#fdafaf',
    light: '#f9f3ed',
    pale: '#fff0f0',
  },
  opacity: {
    white: [
      'rgba(255, 255, 255, 0.04)', // 0
      'rgba(255, 255, 255, 0.08)', // 1
      'rgba(255, 255, 255, 0.12)', // 2
      'rgba(255, 255, 255, 0.16)', // 3
      'rgba(255, 255, 255, 0.24)', // 4
      'rgba(255, 255, 255, 0.32)', // 5
      'rgba(255, 255, 255, 0.40)', // 6
      'rgba(255, 255, 255, 0.52)', // 7
      'rgba(255, 255, 255, 0.64)', // 8
      'rgba(255, 255, 255, 0.76)', // 9
      'rgba(255, 255, 255, 0.88)', // 10
    ],
    black: [
      'rgba(0, 0, 0, 0.04)', // 0
      'rgba(0, 0, 0, 0.08)', // 1
      'rgba(0, 0, 0, 0.12)', // 2
      'rgba(0, 0, 0, 0.16)', // 3
      'rgba(0, 0, 0, 0.24)', // 4
      'rgba(0, 0, 0, 0.32)', // 5
      'rgba(0, 0, 0, 0.40)', // 6
      'rgba(0, 0, 0, 0.52)', // 7
      'rgba(0, 0, 0, 0.64)', // 8
      'rgba(0, 0, 0, 0.76)', // 9
      'rgba(0, 0, 0, 0.88)', // 10
    ],
  },
};
