import { useEffect, useState } from 'react';

import { League, ResultsFilter, SortOrder, useSportResultsByLeagueQuery } from '@news/gql';

const LeagueMapper = new Map<League, '35238' | '275974'>([
  [League.Shl, '35238'],
  [League.Hockeyallsvenskan, '275974'],
]);

function getLeagueValue(key: League): string {
  const value = LeagueMapper.get(key);
  if (!value) {
    throw new Error(`League ${key} not found in LeagueMapper`);
  }
  return value;
}

export const useLeagueSwitcher = ({ routePath }: { routePath: string }) => {
  const [leagueId, setLeagueId] = useState<string>(getLeagueValue(League.Shl));
  const urlIncludesHockeyAllsvenskan = routePath.includes('/hockeyallsvenskan');
  const urlIncludesSHL = routePath.includes('/shl');

  const { data: liveSHLData } = useSportResultsByLeagueQuery({
    variables: {
      leagueId: getLeagueValue(League.Shl),
      offset: 0,
      limit: 8,
      resultsFilter: [ResultsFilter.Live],
      sortDateOrder: SortOrder.Desc,
    },
  });

  const { data: liveHAData } = useSportResultsByLeagueQuery({
    variables: {
      leagueId: getLeagueValue(League.Hockeyallsvenskan),
      offset: 0,
      limit: 8,
      resultsFilter: [ResultsFilter.Live],
      sortDateOrder: SortOrder.Desc,
    },
  });

  const SHLHasLiveMatches =
    liveSHLData?.sportResultsByLeague.results && liveSHLData?.sportResultsByLeague.results.length > 0;
  const HAHasLiveMatches =
    liveHAData?.sportResultsByLeague.results && liveHAData?.sportResultsByLeague.results.length > 0;

  useEffect(() => {
    if (urlIncludesSHL) {
      setLeagueId(getLeagueValue(League.Shl));
    } else if (urlIncludesHockeyAllsvenskan) {
      setLeagueId(getLeagueValue(League.Hockeyallsvenskan));
    } else if (SHLHasLiveMatches || (SHLHasLiveMatches && HAHasLiveMatches)) {
      setLeagueId(getLeagueValue(League.Shl));
    } else if (HAHasLiveMatches && !SHLHasLiveMatches) {
      setLeagueId(getLeagueValue(League.Hockeyallsvenskan));
    } else if (!urlIncludesHockeyAllsvenskan && !urlIncludesSHL) {
      setLeagueId(getLeagueValue(League.Shl));
    } else {
      urlIncludesHockeyAllsvenskan
        ? setLeagueId(getLeagueValue(League.Hockeyallsvenskan))
        : setLeagueId(getLeagueValue(League.Shl));
    }
  }, [SHLHasLiveMatches, HAHasLiveMatches]);

  return { leagueId, setLeagueId };
};
