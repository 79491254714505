import Cookies from 'js-cookie';

const cookieService = Object.assign({}, Cookies, {
  set({ name, value, options }: { name: string; value: string; options?: Cookies.CookieAttributes }) {
    let secure = false;
    if (document.location.protocol === 'https:') {
      secure = true;
    }
    return Cookies.set(name, value, {
      ...options,
      sameSite: 'lax',
      secure,
    });
  },
  get(name: string) {
    return Cookies.get(name);
  },
});

export { cookieService };
