interface Props {
  size?: number;
  className?: string;
}

export const ExploreIcon = ({ size = 24, className }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path d="M3.5 14.5001H9.5V20.5001H3.5V14.5001Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    <path
      d="M20.5 17.5001C20.5 19.4331 18.933 21.0001 17 21.0001C15.067 21.0001 13.5 19.4331 13.5 17.5001C13.5 15.5671 15.067 14.0001 17 14.0001C18.933 14.0001 20.5 15.5671 20.5 17.5001Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M12 3.50006L16 10.3572H8L12 3.50006Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
  </svg>
);
