interface Props {
  size?: number;
  className?: string;
}

export const TableauIcon = ({ className, size = 24 }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3544 0.231804C16.7787 0.585368 16.836 1.21593 16.4824 1.64021L12.8493 5.9999H20.3242C21.2497 5.9999 21.9999 6.75013 21.9999 7.67558V18.3242C21.9999 19.2497 21.2497 19.9999 20.3242 19.9999H3.67558C2.75013 19.9999 1.99991 19.2497 1.99991 18.3242V7.67558C1.99991 6.75013 2.75013 5.9999 3.67558 5.9999H9.87129L7.2928 3.42142C6.90228 3.03089 6.90228 2.39773 7.2928 2.0072C7.68333 1.61668 8.31649 1.61668 8.70702 2.0072L11.3613 4.66147L14.946 0.359841C15.2995 -0.0644362 15.9301 -0.12176 16.3544 0.231804ZM3.99991 7.9999V17.9999H19.9999V7.9999H3.99991ZM7.34366 22C7.34366 21.4477 7.79137 21 8.34366 21H15.9374C16.4897 21 16.9374 21.4477 16.9374 22C16.9374 22.5523 16.4897 23 15.9374 23H8.34366C7.79137 23 7.34366 22.5523 7.34366 22Z"
      fill="currentColor"
    />
  </svg>
);
