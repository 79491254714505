import type { ScheduleFieldsFragment } from '@news/gql';

interface Props {
  todaysDate: string;
  dayToFetch: string;
  schedule: ScheduleFieldsFragment[];
}

const isInvalidDate = (date: number): boolean => {
  return Number.isNaN(date);
};

export const hasInvalidScheduleData = ({ todaysDate, dayToFetch, schedule }: Props) => {
  const today = Date.parse(todaysDate);
  const requestedDate = Date.parse(dayToFetch);
  if (isInvalidDate(today) || isInvalidDate(requestedDate)) return false;

  return today !== requestedDate && schedule.length === 0;
};
