import { IMAGE_PROXY_URL } from './constants';

export interface ImageConversionArgs {
  blur?: number;
  dark?: number;
  width?: number;
  url?: string | null;
}

const FALLBACK_IMAGE = 'https://www.tv4.se/imageproxy-fallback.png';

const ImageProxy = {
  url: function ({ blur, dark, url, width }: ImageConversionArgs) {
    const imageUrl = url || FALLBACK_IMAGE;
    const protocol = imageUrl.match(/^\/\//) ? 'https:' : '';
    const source = encodeURIComponent(protocol + imageUrl);

    const params: string[] = [`source=${source}`, `width=${width}`];
    if (blur) params.push(`blur=${blur}`);
    if (dark) params.push(`dark=${dark}`);

    return `${IMAGE_PROXY_URL}?${params.join('&')}`;
  },
};

export { ImageProxy };
