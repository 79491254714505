import type { RelatedArticleFieldsFragment } from '@news/gql';
import { getBylineNames, getImageFromFeatured } from '@news/lib';
import type { NewsEntry } from '@news/lib';

import type { KilkayaPageView } from '../kilkaya';

type KilkayaPageData = Pick<
  KilkayaPageView,
  'authors' | 'imageUrl' | 'publishDate' | 'modifiedDate' | 'tags' | 'wordcount'
>;

export const getNewsEntryKilkayaPageData = (item: NewsEntry | RelatedArticleFieldsFragment): KilkayaPageData => {
  switch (item.__typename) {
    case 'Article':
    case 'RelatedArticle':
      return {
        authors: getBylineNames(item),
        imageUrl: getImageFromFeatured(item.featuredAsset),
        publishDate: item.publishedDateTime ?? undefined,
        modifiedDate: item.updatedDateTime ?? undefined,
        tags: item.feedOrigins[0]?.title ? [item.feedOrigins[0]?.title] : undefined,
        wordcount: item.metadata?.wordCount ?? undefined,
      };
    case 'VideoAsset': {
      return {
        imageUrl: item.image ?? undefined,
        publishDate: item.publishedDateTime ?? undefined,
      };
    }
  }
};
