import React from 'react';

import { cssSize } from './iconUtils';

export const TvIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    style={cssSize(24)}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0-TvIcon)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3649 0.221264C16.7949 0.579606 16.853 1.21869 16.4947 1.6487L12.8801 5.98623H20.3261C21.259 5.98623 22.0153 6.74251 22.0153 7.67542V18.3241C22.0153 19.257 21.259 20.0133 20.3261 20.0133H3.67747C2.74456 20.0133 1.98828 19.257 1.98828 18.3241V7.67542C1.98828 6.74251 2.74456 5.98623 3.67747 5.98623H9.84055L7.28513 3.43081C6.88933 3.03501 6.88933 2.39329 7.28513 1.99749C7.68093 1.60169 8.32266 1.60169 8.71846 1.99749L11.3623 4.64129L14.9375 0.351031C15.2958 -0.0789789 15.9349 -0.137078 16.3649 0.221264ZM4.01531 8.01326V17.9862H19.9883V8.01326H4.01531ZM8.34554 20.9864C7.7858 20.9864 7.33203 21.4401 7.33203 21.9999C7.33203 22.5596 7.7858 23.0134 8.34554 23.0134H15.9393C16.499 23.0134 16.9528 22.5596 16.9528 21.9999C16.9528 21.4401 16.499 20.9864 15.9393 20.9864H8.34554Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0-TvIcon">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
