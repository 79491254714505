import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const NyhetsmorgonLogoIcon = ({ className, size = 24 }: Props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2661.48 2661.48"
    className={className}
  >
    <g>
      <circle fill="currentColor" cx="1330.74" cy="1330.7" r="560.31" />
      <g>
        <path
          fill="none"
          d="m1330.74,2003.42c90.82,0,178.91-17.78,261.83-52.85,80.1-33.88,152.04-82.38,213.83-144.17s110.29-133.73,144.17-213.83c35.07-82.92,52.85-171.01,52.85-261.83s-17.78-178.91-52.85-261.83c-33.88-80.1-82.38-152.04-144.17-213.83s-133.72-110.29-213.83-144.17c-82.92-35.07-171.01-52.85-261.83-52.85s-178.91,17.78-261.83,52.85c-80.1,33.88-152.04,82.38-213.83,144.17-61.78,61.78-110.29,133.73-144.17,213.83-35.07,82.92-52.85,171.01-52.85,261.83s17.78,178.91,52.85,261.83c33.88,80.1,82.38,152.04,144.17,213.83,61.78,61.78,133.73,110.29,213.83,144.17,82.92,35.07,171.01,52.85,261.83,52.85Z"
        />
        <path
          fill="currentColor"
          d="m2655.99,1323.55s-415.26-133.36-587.03-188.53c120.92-134.6,413.03-459.77,413.03-459.77,5.29-5.41.1-14.35-7.22-12.44,0,0-426.51,91.62-602.73,129.47,37.48-177.03,128.28-605.87,128.28-605.87,1.87-7.33-7.09-12.48-12.48-7.16,0,0-323.65,293.02-457.53,414.24C1473.99,421.32,1337.93,5.45,1337.93,5.45c-2.04-7.29-12.38-7.26-14.38.03,0,0-133.18,414.82-188.37,586.74-134.93-121.13-460.38-413.3-460.38-413.3-5.41-5.29-14.35-.11-12.44,7.22,0,0,92.1,426.72,130.14,602.98-177.29-37.18-606.16-127.13-606.16-127.13-7.33-1.88-12.48,7.08-7.17,12.47,0,0,293.29,322.89,414.51,456.35C421.5,1187.23,5.46,1323.55,5.46,1323.55c-7.29,2.04-7.27,12.38.03,14.38,0,0,415.12,133.06,586.87,188.11-121.09,134.67-413.62,460.01-413.62,460.01-5.29,5.41-.11,14.35,7.22,12.44,0,0,427.67-91.89,603.65-129.71-37.23,176.9-127.72,606.84-127.72,606.84-1.87,7.33,7.09,12.48,12.48,7.16,0,0,323.34-293.52,456.97-414.83,56.26,172.11,192.22,588.07,192.22,588.07,2.04,7.29,12.38,7.26,14.38-.04,0,0,133.41-415.16,188.62-586.99,134.82,121.07,460.64,413.64,460.64,413.64,5.41,5.29,14.35.1,12.44-7.22,0,0-92.17-426.76-130.29-603.21,177.09,37.5,605.06,128.11,605.06,128.11,7.33,1.88,12.48-7.09,7.16-12.48,0,0-292.81-323.75-413.79-457.52,172.03-56.26,588.26-192.39,588.26-192.39,7.29-2.04,7.26-12.38-.04-14.38Zm-1800.91,482.85c-61.78-61.78-110.29-133.73-144.17-213.83-35.07-82.92-52.85-171.01-52.85-261.83s17.78-178.91,52.85-261.83c33.88-80.1,82.38-152.04,144.17-213.83,61.78-61.78,133.73-110.29,213.83-144.17,82.92-35.07,171.01-52.85,261.83-52.85s178.91,17.78,261.83,52.85c80.1,33.88,152.04,82.38,213.83,144.17s110.29,133.73,144.17,213.83c35.07,82.92,52.85,171.01,52.85,261.83s-17.78,178.91-52.85,261.83c-33.88,80.1-82.38,152.04-144.17,213.83s-133.72,110.29-213.83,144.17c-82.92,35.07-171.01,52.85-261.83,52.85s-178.91-17.78-261.83-52.85c-80.1-33.88-152.04-82.38-213.83-144.17Z"
        />
      </g>
    </g>
  </svg>
);
