const enum BROWSER {
  CHROME = 'Chrome',
  SAFARI = 'Safari',
  IE = 'IE',
  EDGE = 'Edge',
  FIREFOX = 'Firefox',
  IOS_FIREFOX = 'iOS Firefox',
  UNKNOWN = 'Unknown',
}

const DesktopBrowserMaintained = {
  [BROWSER.IOS_FIREFOX]: 28,
  [BROWSER.FIREFOX]: 50,
  [BROWSER.CHROME]: 75,
  [BROWSER.IE]: 11,
  [BROWSER.EDGE]: 17,
  [BROWSER.SAFARI]: 10,
} as const;

interface BrowserInfo {
  name: BROWSER;
  version: string;
}

export const isBrowserSupported = (navigator: Navigator): boolean => {
  const browser = getBrowserAndBrowserVersion(navigator);
  if (browser.name === BROWSER.UNKNOWN) {
    return true;
  }
  if (browser.name === BROWSER.IE) {
    return false;
  }
  const isSupported = parseInt(browser.version) >= DesktopBrowserMaintained[browser.name];
  return isSupported;
};

const getBrowserAndBrowserVersion = (navigator: Navigator): BrowserInfo => {
  const userAgent = navigator.userAgent.toLowerCase();
  const vendor = navigator.vendor.toLowerCase();

  return (
    isFirefox(userAgent) ||
    isChrome(userAgent, vendor) ||
    isSafari(userAgent) ||
    isIE(userAgent) ||
    isEdge(userAgent) || { name: BROWSER.UNKNOWN, version: '1' }
  );
};

function isChrome(userAgent: string, vendor: string): BrowserInfo | undefined {
  const isChrome = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/i) : null;
  if (isChrome && !/edge|edg/.test(userAgent) && isChrome[1]) {
    return {
      name: BROWSER.CHROME,
      version: isChrome[1],
    };
  } else {
    return;
  }
}

function isSafari(userAgent: string): BrowserInfo | undefined {
  const isSafari = userAgent.match(/version\/(\d+).+?safari/i);
  if (isSafari && isSafari[1]) {
    return {
      name: BROWSER.SAFARI,
      version: isSafari[1],
    };
  } else {
    return;
  }
}

function isIE(userAgent: string): BrowserInfo | undefined {
  const isInternetExplorer = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/i);
  if (isInternetExplorer && isInternetExplorer[1]) {
    return {
      name: BROWSER.IE,
      version: isInternetExplorer[1],
    };
  } else {
    return;
  }
}

function isFirefox(userAgent: string): BrowserInfo | undefined {
  const isFirefox = userAgent.match(/(?:firefox|fxios)\/(\d+)/i);
  if (isFirefox && isFirefox[1]) {
    if (isFirefox[0].includes('fxios')) {
      return {
        name: BROWSER.IOS_FIREFOX,
        version: isFirefox[1],
      };
    } else {
      return {
        name: BROWSER.FIREFOX,
        version: isFirefox[1],
      };
    }
  } else {
    return;
  }
}

function isEdge(userAgent: string): BrowserInfo | undefined {
  const isEdge = userAgent.match(/(?:edge|edg)\/(\d+)/i);
  if (isEdge && isEdge[1]) {
    return {
      name: BROWSER.EDGE,
      version: isEdge[1],
    };
  } else {
    return;
  }
}

export const isMobileSafari = () => {
  if (typeof window === 'undefined') return;
  return window.navigator.maxTouchPoints > 0 && !!isSafari(window.navigator.userAgent);
};
